import React, { Component, Fragment } from 'react';

class CountryJPN extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-jpn'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>JEPANG</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                        Jepang adalah negara dengan tingkat teknologi yang lebih maju 10 tahun di bandingkan negara lainnya.
                                        Jepang merupakan negara maju yang sangat siap untuk International Student . Mereka memiliki sistem pendidikan yang sangat baik sekali dengan Top-Performing OECD country dalam hal “Reading Literacy, Math and Science”. 94.6 % warga Jepang juga menempuh pendidikan tinggi/universitas.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>JEPANG</strong></h1>
                                <h4><strong>Mengapa Jepang</strong></h4>
                                <p className='text-justify'>
                                    Jepang merupakan negara no 3 dengan ekonomi terbesar di dunia. Jepang memiliki banyak sekali perusahan multinational di bidang teknologi, automotive, dan manifaktur. Dengan populasi Jepang yang menua, menjadikan jepang menjadi tempat yang manawarkan banyak kesempatan untuk International Student untuk bisa bekerja dan tinggal disana.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE UNIVERSITY</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>4,8 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>2,4 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>400 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>125 ribu / Jam</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Japan</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-6'>
                                <img className='img-fluid mb-3' src="../../assets/img/country/JPN/tokyo.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Tokyo</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img className='img-fluid mb-3' src="../../assets/img/country/JPN/kyoto.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Kyoto</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Japan</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/JPN/fuji.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Mt. Fuji</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/JPN/Shinjuku.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Shinjuku</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/JPN/tea-ceremony.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Tea Ceremony wearing Kimono</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryJPN);