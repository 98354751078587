import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import API_URL from '../../ApiUrl';

const Tracking = () => {
    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [tracking, setTracking] = useState([]);
    const [isLimit, setIsLimit] = useState(false);
    useEffect(()=>{
        getOrderByResi();
    }, [])

    const getOrderByResi = () => {
        axios.get(API_URL.superStudent2 + '/niec-merchandise/tracking/' + id)
        .then(res => {
            const {data} = res.data
            setOrder(data);
            if (data.count_tracking <= 5) {
                getOrderTracking(data);
            } else {
                setIsLimit(true);
            }
        })
        .catch(err => console.log(err))
    }

    const getOrderTracking = (data) => {
        axios.get('https://api.biteship.com/v1/trackings/' + data.waybill_id + '/couriers/' + data.courier_company,{
            headers: {
                Authorization: `Bearer ${API_URL.biteship_token}`
            }
        })
        .then(res => {
            setTracking(res.data.history)
        })
        .catch(err => console.log(err))
    }

    return (
      <>
      <div className="container">
        <h2 className='text-center mt-5'>Tracking Order</h2>
        <h4 className='text-center'>{order.nama_lengkap}</h4>
        <p className='text-center'>No Resi : {order.no_resi}</p>
        <p className='text-center'>{`( Anda hanya dapat melacak paket sebanyak 5 kali)`}</p>
        <h4 className='text-center'>{`Anda telah melihat pelacakan paket sebanyak ${order.count_tracking == 6 ? '5' : order.count_tracking} kali.`}</h4>
        <div className="box-tracking">
            <div class="feature-list">
            {
                isLimit && <h4 className='text-center'>Anda hanya boleh melihat riwayat pengiriman sebanyak 5 kali, anda dapat mengklik <a href="https://biteship.com/id/cek-resi">link berikut</a> sebagai alternatif.</h4>
            }    
            {
                !isLimit &&
                tracking.map((arr) => {
                    const date = new Date(arr.eventDate);
                    return (
                        <div class="step">
                            <div class="v-stepper">
                                <div class="box"></div>
                                <div class="line"></div>
                            </div>
                            <div class="content">
                                <h5>{arr.note}</h5>
                                {/* <p>{arr.eventDate}</p> */}
                                <p>{date.toDateString()}</p>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
      </div>
      </>  
    )
}

export default Tracking