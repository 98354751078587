import React, { Component } from 'react';

class About extends Component {

    render() {
        
        return (
            <div>
                <section className='bg-default'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <img className='img-fluid rounded float-right' src="../../../assets/img/slide/about/IMG_0577.jpg"></img>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h1 className='text-center mb-5'><strong>Our Motivation</strong></h1>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <img className='img-fluid' src="../../../assets/img/icon/icon mov-01.png" />
                                    </div>
                                    <div className='col-lg-7'>
                                        <h1 className='color-default mt-4'><strong>DREAM</strong></h1>
                                    </div>
                                </div>
                                <p className='text-justify mt-3'>
                                    Mimpi adalah sebuat keinginan besar yang
                                    ada di dalam hati kita. NIEC memiliki mimpi
                                    untuk dapat membantu semua siswa
                                    Indonesia yang juga mempunyai mimpi
                                    untuk meraih pendidikan terbaik dan
                                    mencapai suksesnya.
                                </p>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <img className='img-fluid' src="../../../assets/img/icon/icon mov-02.png" />
                                    </div>
                                    <div className='col-lg-7'>
                                        <h1 className='color-default mt-4'><strong>ACT</strong></h1>
                                    </div>
                                </div>
                                <p className='text-justify mt-3'>
                                    Tindakan adalah sebuat proses perjalanan
                                    untuk memujudkan mimpi dan kesuksesan.
                                    di NIEC kami selalu mengevaluasi proses
                                    tindakan dan perjalanan kami sebagai
                                    sebuat konsultant pendidikan. Dan kami
                                    mengajak semua siswa kami untuk berani
                                    melalui proses dan melakukan tindakan
                                    untuk mencapai mimpi mereka untuk
                                    meraik kesuksesan
                                </p>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <img className='img-fluid' src="../../../assets/img/icon/icon mov-03.png" />
                                    </div>
                                    <div className='col-lg-7'>
                                        <h1 className='color-default mt-4'><strong>SUCCED</strong></h1>
                                    </div>
                                </div>
                                <p className='text-justify mt-3'>
                                    Kesuksesan adalah cita cita dari mimpi dan
                                    tindakan kami. NIEC ingin membuat bangsa
                                    Indonesia bangga dengan kesuksesan siswa
                                    Indonesia di dunia global dan membuat
                                    semua orang tua siswa bangga terhadap
                                    anak mereka.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-abu'>
                    <div className='container'>
                        <h1 className='text-center mb-5'><strong>Mengapa NIEC Indonesia</strong></h1>
                        <div className='row'>
                            <div className='col-4 text-left'>
                                <div className='mb-4'>
                                    <h3><strong>1</strong></h3>
                                    <h4 className='color-default'><strong>Informasi Cepat dan Tepat</strong></h4>
                                    <p><strong>Kami memberikan informasi akurat dan cepat mengenai pendidikan ke luar negeri</strong></p>
                                </div>
                                <div className='mb-4'>
                                    <h3><strong>2</strong></h3>
                                    <h4 className='color-default'><strong>Pelayanan Penuh Setiap Saat</strong></h4>
                                    <p>
                                        <strong>
                                            NIEC berdedikasi memberikan pelayanan full
                                            time bagi siswa dan orang tua yang ingin
                                            konsultasi. Kapan pun dan di manapun, setiap
                                            siswa dan orang tua dapat menghubungi
                                            kami melalui konsultasi di kantor ataupuan
                                            online platform kami.
                                        </strong>
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3><strong>3</strong></h3>
                                    <h4 className='color-default'><strong>Banyak Pilihan Negara dan Sekolah</strong></h4>
                                    <p><strong>Kami memberikan informasi akurat dan cepat mengenai pendidikan ke luar negeri</strong></p>
                                </div>
                            </div>
                            <div className='col-4'>
                                <img className='img-fluid' src="../../../assets/img/img-01.png"  />
                            </div>
                            <div className='col-4'>
                                <div className='mb-4'>
                                    <h3><strong>4</strong></h3>
                                    <h4 className='color-default'><strong>Relasi yang erat dengan siswa dan orang tua</strong></h4>
                                    <p>
                                        <strong> 
                                        NIEC berfondasikan prinsip untuk menjaga
                                        hubungan yang erat dan kekeluargaan
                                        dengan semua siswa dan orang tua siswa.
                                        </strong>
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3><strong>5</strong></h3>
                                    <h4 className='color-default'><strong>Penghubung Siswa, Keluarga dan Sekolah</strong></h4>
                                    <p> 
                                        <strong> 
                                        NIEC menjadi perantara bagi siswa, keluarga dan sekolah untuk selalu terhubung.
                                        </strong>
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <h3><strong>6</strong></h3>
                                    <h4 className='color-default'><strong>Pembekalan Siswa Sebelum Keberangkatan</strong></h4>
                                    <p> 
                                        <strong>
                                        Semua siswa NIEC diberikan pembekalan
                                        sebelum keberangkatan untuk
                                        mempersiapkan mereka dalam hal budaya
                                        dan kemampuan mencari kerja.
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (About);