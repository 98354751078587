import React, { Component } from 'react'
import CareerView from './CareerView'
import axios from 'axios';
import API_URL from '../../ApiUrl';


export class Career extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_lowongan: [],
            data_area: [],
            list_jobdesk: []
        }
    }

    componentDidMount(){
        this.getDataArea();
        this.getDataLowongan();
        this.getJobDesk();
    }

    filterLowongan = (filter) => {
        axios.post(API_URL.superStudent2 + '/niec-career/filter-lowongan', filter)
        .then(res =>  {
        this.setState({ data_lowongan: res.data.data})
        })
        .catch(error => console.log(error))
    }

    getDataArea = () => {
        axios.get(API_URL.superStudent2 + '/master/area')
        .then(response => this.setState({ data_area: response.data.data}))
        .catch(error => console.log(error))
    }

    getJobDesk(){
        axios.get(API_URL.superStudent2 + '/consultant/list-jobdesk')
        .then(response => this.setState({ list_jobdesk: response.data.data}))
        .catch(error => console.log(error))
    }
    
    getDataLowongan(){
        axios.get(API_URL.superStudent2 + '/niec-career/loker')
        .then(response => response.data)
        .then(result => {
        this.setState({ data_lowongan: result})
        })
        .catch(error => console.log(error))
    }

  render() {
    const {data_lowongan, data_area, list_jobdesk} = this.state
    return (
        <CareerView 
            data={data_lowongan} 
            area={data_area}
            list_jobdesk={list_jobdesk}
            filterLowongan={this.filterLowongan}/>
    )
  }
}

export default Career