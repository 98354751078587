import React, { Component, Fragment } from 'react';

class CountryGER extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-ger'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>Germany</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    Jerman adalah tempat yang sangat populer untuk perkuliahan. Jerman menawarkan pendidikan gratis untuk jenjang universitas dan kesempatan untuk mendapatkan pekerjaan setelah lulus dari campus di jerman.
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>GERMANY</strong></h1>
                                <h4><strong>Mengapa Germany</strong></h4>
                                <p className='text-justify'>
                                    Jerman memberikan akses pendidikan gratis bagi kamu yang bisa melalui seleksi masuk universitas disana. Sebelum mengikuti ujian masuk universitas, kamu bisa mengikuti program studienkolleg untuk persiapan nya. Universitas public di jerman biasanya menggunakan bahasa pengantar bahasa jerman.
                                </p>
                                <p className='text-justify'>
                                    Namun, jika kamu tidak bisa menggunakan bahasa Jerman, kamu bisa memilih kampus kampus yang mengunakan bahasa pengantar bahasa Ingris. Banyak kampus swasta disana yang menyediakan perkuliahan dengan bahasa Ingris dengan biaya yang relative murah.
                                </p>
                                <p className='text-justify'>
                                    Jerman juga meminta “block account” dimana ketika kamu mengajukan visa, harus menyimpan uang di bank di jerman yang nanti nya akan kamu gunakan untuk memenuhi kebutuhan hidup bulanan mu.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>Menggunakan Bahasa Jerman</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>Menggunakan Bahasa Inggris</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>5,5 juta / Minggu</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>2,3 ribu / Minggu</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>780 ribu / Minggu</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>150 ribu / jam</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Germany</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/berlin2.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Berlin</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/munich.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Munich</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/frankfurt.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Frankfurt</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/Hamburg.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Hamburg</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/stuttgart.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Stuttgard</strong></h3>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Germany</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/Cologne-Cathedral.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Cologne Cathedral</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/GER/NeuschwansteinCastle.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Neuschwanstein Castle</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryGER);