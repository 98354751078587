import React, { Component, Fragment } from 'react';

class CountryTUR extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-tur'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>TURKI</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                    Turki adalah negara yang berada di benua Eropa dan Asia. Karena letaknya ini, Turki menjadi pusat perdagangan dunia yang sudah berjalan lebih dari ratusan tahun lamanya.
                                    Dengan kebudayaan yang majemuk ini, menjadikan Turki sebagai negara yang sangat ramah dengan orang orang dari berbagai macam negara, suku bangsa dan agama.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>TURKI</strong></h1>
                                <h4><strong>Mengapa Turki</strong></h4>
                                <p className='text-justify'>
                                    Turkey adalah salah satu negara yang students friendly yang di setiap kotanya terdapat minimal satu universitas dengan fasilitas dan teknologi yang memadai. Turki punya lebih dari 200 universitas yang nawarin 6.000 program studi yang tentunya akan memudahkan kalian untuk menemukan program studi yang sesuai dengan minat dan bakatmu. Gak hanya itu, lingkungan yang multikultural udah sangat umum di Turki sehingga gak akan susah bagi pendatang untuk bersosialisasi dan berbaur disana.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE UNIVERSITY</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>2 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>600 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>300 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>6 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Turkey</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-6'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/TUR/istanbul2.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Istanbul</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/TUR/izmir.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Izmir</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Turkey</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/TUR/cappadocia.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Hot air Baloon capadocia</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/TUR/bosphorus.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Bosphorus River</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/TUR/pamukkale.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Pamukkale</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/TUR/hagiasophia.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Hagia Sophia</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryTUR);