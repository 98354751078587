import React, { useEffect, useState } from 'react'
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import { FormatRupiah } from '../services/FormatRupiah';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'

const Payment = ({isMember, activeStep, ongkir, items, order, registrasi}) => {

    const history = useHistory()
    const [snapToken, setSnapToken] = useState('');
    console.log('regis',registrasi)
    console.log('order',order)

    const handleMidtrans = () => {
        order.total = ongkir.total
        order.code_konsultan = registrasi.code_konsultan
        if (snapToken === '') {
            axios({
                method: 'POST',
                url: API_URL.superStudent2 + '/niec-merchandise/checkout',
                data: order,
                headers: {
                }
            }).then(res => {
                setSnapToken(res.data.data)
                window.snap.pay(res.data.data,{
                    onSuccess: function(result){
                        alert('success');
                        localStorage.removeItem('react-use-cart')
                        Swal.fire({
                        title: 'Terima Kasih Sudah Membeli!',
                        text: 'Pesanan kamu akan segera kami proses, harap ditunggu ya ;)',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                        }).then((result)=>{
                        history.push({
                            pathname: '/merchandise'
                        })
                    })
                    },
                    onPending: function(result){
                        alert("wating your payment!"); 
                    //   console.log(result);
                        history.push({
                        pathname: '/merchandise'
                        })
                    },
                    onError: function(result){
                        alert("payment failed!"); 
                        console.log(result);
                    }
                });
            })
            .catch(err => console.log(err))
        }    
        else {
            window.snap.pay(snapToken,{
                onSuccess: function(result){
                  alert('success');
                },
                onPending: function(result){
                  alert("wating your payment!"); console.log(result);
                },
                onError: function(result){
                  alert("payment failed!"); console.log(result);
                }
            });
        }   
    }

    // const sendDataRegistrasi = () => {
    //     axios.post(API_URL.superStudent + '/membership/registration', registrasi, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             'Authorization': `Bearer ${API_URL.keyMembership}`
    //         }
    //     }).then(res => {
    //         console.log(res)
    //     })
    //     .catch(err => console.log(err))
    // }
    
  return (
    <div className="box-form">
        <h3>Pembayaran</h3>
        <table className='w-100'>
            <tr>
                <td><h5>Total Belanja</h5></td>
                <td></td>
            </tr>
            {
                items?.map((prod) => {
                return (
                <>
                <tr>
                    <td>{prod.name}  ({prod.quantity} buah)</td>
                    <td className='text-end'>{FormatRupiah(prod.value)}</td>
                </tr>
                </>
                )
                })
            }
            <tr>
                {(activeStep == 1) || ((isMember) && (activeStep === 3)) || ((!isMember) && (activeStep === 2))  ? (
                    <>
                    <td>Ongkos Kirim</td>
                    <td className='text-end'>{FormatRupiah(ongkir.ongkir)}</td>
                    </>
                ) : ''
                }
            </tr>   
        </table>
        <hr />
        <table className='w-100'>
            <tr>
                <td>Total</td>
                <td className='text-end'>{FormatRupiah(ongkir.total)}</td>
            </tr>
        </table>
        {
            ((isMember) && (activeStep === 3)) || ((!isMember) && (activeStep === 2))  ?
            (<div className="text-end mt-5">
                <button type='submit' className='btn btn-success' onClick={handleMidtrans}>Bayar</button>
            </div>) : ''
        }
    </div>
  )
}

export default Payment