import React, { Component, Fragment } from 'react';

class Singaraja extends Component {

    render() {
        return (
            <div>
                
            </div>
        )
    }

}

export default (Singaraja);