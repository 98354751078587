import React, { useEffect, useState } from 'react'
import { Stepper } from 'react-form-stepper';
import { useLocation } from 'react-router-dom';
import FormAlamat from './form/FormAlamat';
import FormRegisMember from './form/FormRegisMember';
import FormPengiriman from './form/FormPengiriman';
import Payment from './form/Payment';

const Checkout = () => {
    const location = useLocation();
    const items = location.state.items;
    const isMember = location.state.isMember
    let steps = []
    if (isMember) {
        steps = [
            { label: 'Alamat' },
            { label: 'Pengiriman' },
            { label: 'Registrasi Member' },
            { label: 'Pembayaran'},
        ];
    } else {
        steps = [
            { label: 'Alamat' },
            { label: 'Pengiriman' },
            { label: 'Pembayaran'},
        ];
    }

    const [ activeStep, setActiveStep ] = useState(0);
    
    const [infoAlamat, setInfoAlamat] = useState({})
    const [registrasi, setRegistrasi] = useState({})

    const cartTotal = location.state.cartTotal
    const [kurir, setKurir] = useState({})
    const [ongkir, setOngkir] = useState({ongkir: 0, total: cartTotal})

    const order = {
        destination_contact_name: infoAlamat.nama_lengkap,
        destination_contact_phone: infoAlamat.notelp ?? "",
        destination_address: infoAlamat.alamat ?? "", 
        destination_contact_email: infoAlamat.email ?? "", 
        destination_postal_code: infoAlamat.postal_code ?? '', 
        courier_company: ongkir.courier_code ?? "",
        courier_type: ongkir.courier_type ?? "", 
        courier_insurance: 500000, 
        delivery_type:"now",
        ongkir: ongkir.ongkir,
        items: items
    }

    const nextStep = () => {
        setActiveStep(activeStep + 1)
    }

  const getSectionComponent = () => {
    switch(activeStep) {
      case 0: return <FormAlamat setKurir={setKurir} items={items} nextStep={nextStep} infoAlamat={infoAlamat} setInfoAlamat={setInfoAlamat} />;
      case 1: return <FormPengiriman kurir={kurir} ongkir={ongkir} setOngkir={setOngkir} nextStep={nextStep} cartTotal={cartTotal}/>;
      case 2: return '';
      default: return null;
    }
  }
  const getSectionComponentRegis = () => {
    switch(activeStep) {
      case 0: return <FormAlamat setKurir={setKurir} items={items} nextStep={nextStep} infoAlamat={infoAlamat} setInfoAlamat={setInfoAlamat}/>;
      case 1: return <FormPengiriman kurir={kurir} ongkir={ongkir} nextStep={nextStep} setOngkir={setOngkir} cartTotal={cartTotal}/>;
      case 2: return <FormRegisMember infoAlamat={infoAlamat} setRegistrasi={setRegistrasi} nextStep={nextStep} />;
      case 3: return '';
      default: return null;
    }
  }
  // console.log('sdas', registrasi)
  // console.log('kurir', kurir)
  // console.log('item',items)
  // console.log('fff',items)
  // console.log('order :', order)
  // console.log('ongkir', ongkir)
  // console.log('infoAlamat', infoAlamat)
  return (
    <>
        <section className='merchandise'>
            <div className="container content-merchandise">
            <Stepper
                steps={steps}
                activeStep={activeStep}/>
            <div className="row">
            {
                (isMember) && (activeStep !== 3) ?
                (<div className="col-lg-6">
                { isMember ? getSectionComponentRegis() : getSectionComponent() }
                </div>) :
                (!isMember) && (activeStep !== 2) ?
                (<div className="col-lg-6">
                { isMember ? getSectionComponentRegis() : getSectionComponent() }
                </div>) 
                : ''
            }
                <div className="col-lg-6">
                    <Payment ongkir={ongkir} items={items} activeStep={activeStep} registrasi={registrasi} isMember={isMember} order={order}/>
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default Checkout
