import React, { Component } from "react";

class CountryAUS extends Component {
  render() {
    return (
      <div>
        <section className="banner-lg-aus">
          <div className="container">
            <div className="row text-white">
              <div className="col-lg-6" style={{ marginTop: "300px" }}>
                <h1>
                  <strong>AUSTRALIA</strong>
                </h1>
                <h4>
                  <strong>Guide to Living and Study</strong>
                </h4>
                <p>
                  <strong>
                    Australia merupakan negara tujuan populer siswa
                    international untuk belajar. Selain karena peringkat
                    universitasnya yang tinggi dan berkualitas, juga karena
                    kondisi tinggal di Australia yang nyaman. Australia
                    menawarkan lebih dari 1,200 sekolah dengan lebih dari 22,000
                    jurusan yang bisa kalian pilih sesuai minat. Menariknya
                    lagi, kalian juga mendapat kesempatan bekerja setelah lulus
                    kuliah
                  </strong>
                </p>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
        </section>
        <section className="banner-lg-aus-01">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <img
                  className="img-fluid"
                  src="../../../assets/img/country/AUS/aus-01.png"
                ></img>
              </div>
              <div className="col-lg-7">
                <h1>
                  <strong>AUSTRALIA</strong>
                </h1>
                <h4>
                  <strong>Mengapa Australia</strong>
                </h4>
                <p className="text-justify">
                  Australia merupakan negara tujuan terpopuler keempat untuk
                  siswa international. Selain karena peringkat universitasnya
                  yang tinggi dan berkualitas, juga karena kondisi tinggal di
                  Australia yang nyaman selain itu juga pendidikan di Australia
                  sudah diakui secara international loh.
                </p>
                <p className="text-justify">
                  Kerja sambil belajar. Australia merupakan salah satu negara
                  yang memperbolehkan siswa international untuk bekerja di
                  Australia sambil bekerja jadi selain kalian mendapatkan ilmu
                  pendidikan di sana, kalian juga akan bisa belajar untuk
                  menjadi mandiri.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container border-aus-01">
            <h1 className="mb-5">
              <strong>Tipe Campus</strong>
            </h1>
            <div className="row text-center">
              <div className="col-lg-4">
                <h2>
                  <strong>
                    <i class="bi bi-mortarboard-fill"></i>
                  </strong>
                </h2>
                <h2 className="mb-5">
                  <strong>UNIVERSITY</strong>
                </h2>
                <table className="table-center">
                  <td>
                    <tr>
                      <h5>Teori</h5>
                    </tr>
                    <tr>
                      <h5>Pemerintah + Swasta</h5>
                    </tr>
                    <tr>
                      <h5>S-1 s/d S-3</h5>
                    </tr>
                  </td>
                </table>
              </div>
              <div className="col-lg-4">
                <h2>
                  <strong>
                    <i class="bi bi-mortarboard-fill"></i>
                  </strong>
                </h2>
                <h2 className="mb-5">
                  <strong>POLYTECHNIC/ TAFE</strong>
                </h2>
                <table className="table-center">
                  <td>
                    <tr>
                      <h5>Teori + Praktek</h5>
                    </tr>
                    <tr>
                      <h5>Pemerintah</h5>
                    </tr>
                    <tr>
                      <h5>Diploma s/d S-2</h5>
                    </tr>
                  </td>
                </table>
              </div>
              <div className="col-lg-4 mb-5">
                <h2>
                  <strong>
                    <i class="bi bi-mortarboard-fill"></i>
                  </strong>
                </h2>
                <h2 className="mb-5">
                  <strong>COLLEGE</strong>
                </h2>
                <table className="table-center">
                  <td>
                    <tr>
                      <h5>Teori + Praktek</h5>
                    </tr>
                    <tr>
                      <h5>Swasta</h5>
                    </tr>
                    <tr>
                      <h5>Diploma s/d S-2</h5>
                    </tr>
                  </td>
                </table>
              </div>
            </div>
            <hr />
            <div className="row justify-content-center mb-5">
              <div className="col-xl-3 col-sm-6 col-xs-12 text-center mb-5">
                <div className="akomodasi">
                  <h4 className="mt-5">
                    <strong>Estimasi Dari</strong>
                  </h4>
                  <h2>
                    <strong>Rp. 1,2 juta / minggu</strong>
                  </h2>
                </div>
                <h3>
                  <strong>Tempat Tinggal</strong>
                </h3>
              </div>
              <div className="col-xl-3 col-sm-6 col-xs-12 text-center mb-5">
                <div className="akomodasi">
                  <h4 className="mt-5">
                    <strong>Estimasi Dari</strong>
                  </h4>
                  <h2>
                    <strong>Rp. 500 ribu / minggu</strong>
                  </h2>
                </div>
                <h3>
                  <strong>Makan</strong>
                </h3>
              </div>
              <div className="col-xl-3 col-sm-6 col-xs-12 text-center mb-5">
                <div className="akomodasi">
                  <h4 className="mt-5">
                    <strong>Estimasi Dari</strong>
                  </h4>
                  <h2>
                    <strong>Rp. 100 ribu / minggu</strong>
                  </h2>
                </div>
                <h3>
                  <strong>Transport</strong>
                </h3>
              </div>
              <div className="col-xl-3 col-sm-6 col-xs-12 text-center mb-5">
                <div className="akomodasi-ger">
                  <h4 className="mt-5">
                    <strong>Mulai Dari</strong>
                  </h4>
                  <h2>
                    <strong>200 ribu / jam</strong>
                  </h2>
                </div>
                <h3>
                  <strong>Gaji Part-time</strong>
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-5">
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/sydney.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Sydney</strong>
                  </h3>
                  <p className="text-justify">
                    Terdapat banyak hal yang menarik di Sydney. Mulai dari
                    kuliner yang lezat, tempat berbelanja yang bagus, karya seni
                    yang menginspirasi, hingga budaya dan bangunannya yang
                    menakjubkan.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/perth.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Perth</strong>
                  </h3>
                  <p className="text-justify">
                    Perth merupakan Kota tercerah di Australia dengan pantainya
                    yang berpasir dan berbagai taman di tengah kota.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/melbourne.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Melbourne</strong>
                  </h3>
                  <p className="text-justify">
                    Melbourne terkenal dengan kota yang terdapat banyak event
                    sepanjang tahunnya seperti Australian Open Tennis Grand
                    Slam.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/goldcoast.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Gold Coast</strong>
                  </h3>
                  <p className="text-justify">
                    Gold coast terkenal akan pantai berpasirnya yang membentang
                    memanjang sehingga di nobatkan sebagai spot terbaik untuk
                    berselancar serta terkenal akan sistem kanal dan perairannya
                    yang luas.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/brisbane.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Brisbane</strong>
                  </h3>
                  <p className="text-justify">
                    Brisbane merupakan kota di Australia yang terkenal akan
                    sejarahnya yang menakjubkan, tempat berbelanja yang beragam,
                    bar, hingga panggung music dan seni yang berkembang.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/Adelaide.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Adelaide</strong>
                  </h3>
                  <p className="text-justify">
                    Adelaide merupakan kota yang besar, tetapi tidak sepadat
                    kota-kota besar lain yang ada di Australia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-default">
          <div className="container">
            <h2 className="mb-5">
              <strong>Things to do in Australia</strong>
            </h2>
            <div className="row mb-5">
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/SydneyOpera.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Sydney Opera House</strong>
                  </h3>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/kanggoro.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Kangaroo Island</strong>
                  </h3>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/lordHowe.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Lord Howe Island</strong>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/rottnest.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Rottnest Island</strong>
                  </h3>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/three-sisters.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Three Sisters</strong>
                  </h3>
                </div>
              </div>
              <div className="col-lg-4">
                <img
                  className="img-fluid img-jurusan mb-3"
                  src="../../assets/img/country/AUS/northterm.jpg"
                />
                <div className="card-jurusan container">
                  <h3>
                    <strong>Ayers Rock</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CountryAUS;
