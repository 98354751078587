import { Link } from 'react-router-dom';

const footers = () => {
    return (
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="footer-info text-center footer-logo" style={{marginTop: '-100px'}}>
                                <img class="logo me-auto mb-5 mt-5" height="75" src="../../../assets/img/logo/logo-02.png"></img>
                                <p>We Believe in Dream</p>
                                <p className='mb-5'>Act, and Succeed</p>
                                <h3>Follow Us</h3>
                                <div class="social-links mt-3">
                                    <a href="https://id-id.facebook.com/niec.indonesia/" target='_blank' class="facebook"><i class="bx bxl-facebook"></i></a>
                                    <a href="https://www.instagram.com/niec_indonesia/" target='_blank' class="instagram"><i class="bx bxl-instagram"></i></a>
                                    <a href="https://www.tiktok.com/@niec_indonesia?lang=id-ID" target='_blank' class="instagram"><i class="bx bxl-tiktok"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 footer-links">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">About</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Career</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Destinasi</h4>
                            <div className='row'>
                                <div className='col-lg-6 col-sm-2'>
                                    <ul>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/aus`}><i class="bx bx-chevron-right"></i><a class="active">Australia</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/can`}><i class="bx bx-chevron-right"></i><a class="active">Canada</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/pol`}><i class="bx bx-chevron-right"></i><a class="active">Polandia</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/kor`}><i class="bx bx-chevron-right"></i><a class="active">Korea</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/jpn`}><i class="bx bx-chevron-right"></i><a class="active">Japan</a></Link></li>
                                    </ul>
                                </div>
                                <div className='col-lg-6 col-sm-2'>
                                    <ul>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/usa`}><i class="bx bx-chevron-right"></i><a class="active">USA</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/tur`}><i class="bx bx-chevron-right"></i><a class="active">Turkey</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/nzl`}><i class="bx bx-chevron-right"></i><a class="active">Selandia Baru</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/che`}><i class="bx bx-chevron-right"></i><a class="active">Swiss</a></Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/country/ger`}><i class="bx bx-chevron-right"></i><a class="active">Jerman</a></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 footer-newsletter">
                            <h4>Get In Touch</h4>
                            <p className='text-justify'><i class="bi bi-telephone-fill color-default"></i> +62 812 3843 1234</p>
                            <p className='text-justify'><i class="bi bi-envelope-fill color-default"></i> info@niecindonesia.com</p>
                            <p className='text-justify'><i class="bi bi-geo-alt-fill color-default"></i> Jl. Buluh Indah 56 Denpasar, Bali</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>NIEC Indonesia</span></strong>. All Rights Reserved. CV Naresy Indonesia
                </div>
                <div class="credits">
                    Designed by NIEC IT Team
                </div>
            </div>
        </footer>
    );
}

export default footers;