import React, { useState, useEffect, useRef } from "react";
import "../artikel/artikel.css";
import moment from "moment";
import axios from "axios";
import API_URL from "../../ApiUrl";

const ArtikelItem = ({ data, kategori_artikel }) => {
  const [selectedKategori, setSelectedKategori] = useState(null);
  const [dataArtikelKategori, setDataArtikelKategori] = useState([]);
  const imageRef = useRef(null);

  useEffect(() => {
    getArtikelKategori();
  }, []);

  const getArtikelKategori = async () => {
    try {
      const response = await axios.get(
        `${API_URL.superStudent}/website/artikel-kategori`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      const kategoriData = response.data.data;

      const kategoriWithCount = kategoriData.map((kategori) => {
        const count = data.filter(
          (artikel) =>
            artikel.kategori.code_artikel_kategori ===
              kategori.code_artikel_kategori && artikel.is_publish
        ).length;
        return {
          ...kategori,
          publishedArticles: count,
        };
      });

      setDataArtikelKategori(kategoriWithCount);
    } catch (error) {
      console.error("Error fetching artikel kategori: ", error);
    }
  };

  const handleKategoriChange = (kategoriId) => {
    setSelectedKategori(kategoriId);
  };

  const showAllArtikel = () => {
    setSelectedKategori(null);
  };

  const filteredData = selectedKategori
    ? data.filter(
        (d) =>
          d.kategori.code_artikel_kategori === selectedKategori && d.is_publish
      )
    : data.filter((d) => d.is_publish);

  const getTotalPublishedArticles = () => {
    return data.filter((artikel) => artikel.is_publish).length;
  };

  return (
    <div>
      <section>
        <div className="container">
          <h1 className="text-center mt-3 judulartikel">
            <strong>News NIEC Indonesia</strong>
          </h1>
          <div className="list-artikel">
            <div className="row">
              <div className="col-lg-3">
                <div className="list-group-kategori">
                  <div className="text-center p-1">
                    <h4>Kategori Artikel</h4>
                    <hr />
                  </div>
                  <div className="p-1 d-flex align-items-center justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="artikelKategori"
                        id="semuaArtikel"
                        onChange={showAllArtikel}
                        checked={selectedKategori === null}
                      />
                      <label
                        className="form-check-label ms-1"
                        htmlFor="semuaArtikel"
                      >
                        Semua Artikel
                      </label>
                    </div>
                    <span className="badge bg-primary rounded-pill">
                      {getTotalPublishedArticles()}
                    </span>
                  </div>
                  {kategori_artikel.map((k, i) => {
                    const count = data.filter(
                      (d) =>
                        d.kategori.code_artikel_kategori ===
                          k.code_artikel_kategori && d.is_publish
                    ).length;
                    return (
                      <div
                        key={i}
                        className="p-1 d-flex align-items-center justify-content-between"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="artikelKategori"
                            id={`artikelKategori${i}`}
                            onChange={() =>
                              handleKategoriChange(k.code_artikel_kategori)
                            }
                            checked={
                              selectedKategori === k.code_artikel_kategori
                            }
                          />
                          <label
                            className="form-check-label ms-1"
                            htmlFor={`artikelKategori${i}`}
                          >
                            {k.kategori}
                          </label>
                        </div>
                        <span className="badge bg-primary rounded-pill me-1">
                          {count}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              {filteredData.length === 0 ? (
                <div className="col-lg-8">
                  <p className="text-center">
                    Tidak ada artikel yang ditemukan
                  </p>
                </div>
              ) : (
                filteredData.map((d, i) => {
                  const tanggalSaja = moment(d.updated_at).format(
                    "DD MMMM YYYY"
                  );

                  const extraOffsetColumn =
                    i % 2 === 0 && i !== 0 ? (
                      <div className="col-lg-1"></div>
                    ) : null;
                  const extraColumn =
                    i % 2 === 1 ? <div className="col-lg-2"></div> : null;

                  const maxLength = 90;
                  let shortenedText = d.isi;
                  if (shortenedText.length > maxLength) {
                    shortenedText =
                      shortenedText.substring(0, maxLength) + ".....";
                  }
                  const maxLengthjudul = 19;
                  let shortenedjudul = d.judul;
                  if (shortenedjudul.length > maxLengthjudul) {
                    shortenedjudul =
                      shortenedjudul.substring(0, maxLengthjudul) + "...";
                  }
                  return (
                    <React.Fragment key={`fragment-${i}`}>
                      {extraOffsetColumn}

                      <div className="col-lg-4">
                        <div
                          className="card-artikel"
                          style={{ height: "35rem" }}
                        >
                          <div className="tanggal">
                            <i className="bi bi-calendar4-week"></i>{" "}
                            {tanggalSaja}
                          </div>
                          <br />
                          <h3 className="card-title">
                            <b>{shortenedjudul}</b>
                          </h3>
                          <img
                            style={{
                              maxWidth: "90%",
                              maxHeight: "13rem",
                            }}
                            src={d.image}
                            className="card-img-top-artikel"
                            alt="..."
                          />
                          <h5 className="card-title">{d.sub_judul}</h5>
                          <div className="card-body">
                            <p
                              className="card-text"
                              dangerouslySetInnerHTML={{
                                __html: shortenedText,
                              }}
                            ></p>


                          </div>
                          <a
                              href={`/artikeldetail/${d.slug}`}
                              className="btn btn-primary"
                            >
                              Baca Selengkapnya
                            </a>
                        </div>
                      </div>
                      {extraColumn}
                    </React.Fragment>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArtikelItem;
