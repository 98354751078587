import React, { useEffect, useState } from 'react'
import { useCart } from "react-use-cart";
import { useHistory } from 'react-router-dom';
import { FormatRupiah } from './services/FormatRupiah';

const Cart = ({emptyCart}) => {
  const history = useHistory()
  const [btnDisabled, setBtnDisabled] = useState(false);
  const {
    items,
    totalItems,
    cartTotal,
    updateItemQuantity,
  } = useCart();
  const [isMember, setIsMember] = useState(false)
  
  const checkoutCart = () => {
    let keranjang = []
    setBtnDisabled(true);
    for (let i = 0; i < items.length; i++) {
      keranjang.push({
        name: items[i].name_product,
        description: items[i].code_product,
        value: items[i].itemTotal,
        weight: 1000,
        quantity: items[i].quantity
    })
    }
    history.push({
      pathname: '/merchandise/checkout',
      state: {  // location state
        items: keranjang,
        items_real: items,
        cartTotal: cartTotal,
        isMember: isMember
      },
    })
    setBtnDisabled(false);
  }

  useEffect(() => {
    checkMember();
  }, [isMember])

  const checkMember = () => {
    let member = items.filter(function (el) {
      if (el.is_with_member == 1) {
        setIsMember(true)
      }
    })
  }

  return (
    <>
        <div class="card">
          <div className="card-header d-flex justify-content-between">
            <h5>Ringkasan Belanja</h5>
            <button className='btn btn-danger' onClick={emptyCart}>Kosongkan Keranjang</button>
          </div>
          <div class="card-body">
            <table className='w-100'>
              <tr>
                <td><h5>Total Belanja</h5></td>
                <td></td>
              </tr>
              {
                items?.map((prod) => {
                  return (
                  <>
                  <tr>
                    <td>{prod.name_product}  ({prod.quantity}pcs x {FormatRupiah(prod.price)} )</td>
                    <td className='text-center'>
                    {
                      prod.is_with_member === 0 ? (
                        <>
                        <button className='btn btn-danger' onClick={() => updateItemQuantity(prod.id, prod.quantity - 1)}>-</button>Qty : {prod.quantity}<button className='btn btn-warning' onClick={() => updateItemQuantity(prod.id, prod.quantity + 1)}>+</button>
                        </>
                      ) : ''
                    }
                      </td>
                    <td className='text-end'>{FormatRupiah(prod.itemTotal)}</td>
                  </tr>
                  </>
                  )
                })
              }       
            </table>
            <hr />
            <table className='w-100'>
              <tr>
                <td>Total Harga ({totalItems} Barang)</td>
                <td></td>
                <td className='text-end'>{FormatRupiah(cartTotal)}</td>
              </tr>
            </table>
            
          </div>
          <div className="card-footer">
            <button className={`btn btn-success ${items.length < 1 ? 'disabled' : ''}`} onClick={checkoutCart}>Checkout{btnDisabled && '....'}</button>
          </div>
        </div>
    </>
  )
}

export default Cart