import React, { Component, Fragment } from 'react';

class CountryENG extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-aus container-aus-low'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>UK</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    United Kingdom (UK) terdiri dari 4 negara: England, Scotland, Wales dan Ireland. Meskipun area mereka yang kecil, tapi mereka memiliki budaya dan aksen yang berbeda di setiap wilayahnya.
                                    UK Memberikan kesempatan kepada International Student untuk mendapatkan pengalaman akademik yang sangat sulit untuk di kalahkan negara lain.
                                    1 dari 4 pemimpin dunia pernah mengenyam pendidikan di UK, sehingga jika kamu mau menjadi orang yang memiliki mimpi besar, pastikan kamu menempuh pendidikan di UK.
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>UNITED KINGDOM</strong></h1>
                                <h4><strong>Mengapa UK</strong></h4>
                                <p className='text-justify'>
                                    Study di Uk memberikan world class degree untuk menyiapkan kamu meraih karir yang sukses. Hampir seluruh universitas di UK memiliki ranking terbaik di dunia sehingga bisa dipastikan dimanapun tempat perkulihanmu di UK, kamu akan mendapatkan kesempatan pendidikan terbaik.
                                    UK menawarkan visa untuk tinggal setelah kamu menyelesaikan pendidikan di sana. Sehingga memberikan kesempatan untuk berkarir yang lebih luas di kancah international.
                                    International Student dapat bekerja 20 jam / week
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>FOUNDATION</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Swasta / Pemerintah</h5></tr>
                                        <tr><h5>Pathway untuk ke Universitas di UK</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Swasta / Pemerintah</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>7,2 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>2,7 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1,5 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>175 ribu / Jam</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of UK</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/sydney.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>London</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/perth.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Liverpool</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/perth.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Edinburg</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/sydney.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Dublin</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in UK</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/sydney.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Buckingham Palace</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/perth.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>London Eye</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/aus/melbourne.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Edinburg Castle</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryENG);