import React, { Component, Fragment } from 'react';

class CountryCAN extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-can'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>CANADA</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                        Canada adalah negara yang memberikan kesempatan tanpa batas. Lebih dari 500,000 international students memilih Canada sebagai tempat tujuan kuliahnya. Canada banyak di sukai international student karena kemajemukan penduduknya, kesempatan yang berlimpah untuk bisa tinggal, bekerja dan sekolah disana.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>CANADA</strong></h1>
                                <h4><strong>Mengapa Canada</strong></h4>
                                <p className='text-justify'>
                                    Ketika kamu memutuskan untuk kuliah di Canada, kamu akan menginvestasikan masa depanmu. Setelah selesai kuliah, Campus di Canada akana memberikan kesempatan untuk bisa mendapatkan pekerjaan, peluang bisnis, dan tinggal di Canada.
                                </p>
                                <p className='text-justify'>
                                    Universitas dan College di Canada di akui di seluruh dunia dan biaya perkuliahan termasuk yang termurah di kalangan negara-negara berbahasa inggris.
                                </p>
                                <p className='text-justify'>
                                    Sebagai mahasiswa di Canada, kamu juga akan mendapatkan banyak perlindungan seperti misalahnya: human rights / HAM, keamanan, persamaan hak sehingga membuat kamu merasa aman dan nyaman untuk tinggal di Canada.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-4'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE COLLEGE</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Praktek</h5></tr>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>Diploma</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-4'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC COLLEGE</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Praktek</h5></tr>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>Diploma s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-4 mb-5'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Teori</h5></tr>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>Diploma s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>Rp. 250 ribu / minggu</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>Rp. 250 ribu / minggu</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>Rp. 100 ribu / minggu</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>150 ribu / jam</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Canada</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/vancouver.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Vancouver</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/toronto.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Toronto</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/ontario.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Ontario</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Canada</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/banff.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Banff</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/niagara.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Niagara Fall</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/victoria.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Victoria</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/campilano.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Campilano Suspension Bridge</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CAN/whistler.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Whistler</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryCAN);