import React, { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import { useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import API_URL from "../../ApiUrl";
import { FormatRupiah } from "./services/FormatRupiah";
import Cart from "./Cart";
import ReactImageZoom from "react-image-zoom";

const MerchandiseDetail = () => {
  const location = useLocation();
  const { addItem, inCart, items, emptyCart } = useCart();
  const [showCart, setShowCart] = useState(false);
  const empty_cart = location.state?.empty_cart;
  const { code_product } = useParams();
  const [produk, setProduk] = useState(null);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Menyimpan indeks gambar yang sedang dipilih
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [zoomEnabled, setZoomEnabled] = useState(true);

  const toggle = () => {
    setShowCart(!showCart);
  };

  useEffect(() => {
    fetchProduk(code_product);
  }, [code_product]);

  const fetchProduk = async (code_product) => {
    try {
      const response = await axios.get(
        `${API_URL.superStudent}/api/v1/application/niec-merchandise/produk-detail/${code_product}`
      );
      const data = response.data;
      setProduk(data);

      console.log(data)

      const imagesArray = [];
      if (data.file_pic != null) {
        imagesArray.push(data.file_pic);
      }
      if (data.file_pic1 != null) {
        imagesArray.push(data.file_pic1);
      }
      if (data.file_pic2 != null) {
        imagesArray.push(data.file_pic2);
      }
      if (data.file_pic3 != null) {
        imagesArray.push(data.file_pic3);
      }

      setImages(imagesArray);
      setLoading(false);
    } catch (error) {
      console.error("Data Tidak Masuk:", error);
      setLoading(false);
    }
  };

  const handleAddToCart = (produk) => {
    addItem(produk);
  };

  const handleImageChange = (index) => {
    setCurrentImageIndex(index); // Ubah indeks gambar yang sedang dipilih saat slider berganti
    setCursorPosition({ x: 0, y: 0 });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            className="img-fluid rounded float-right"
            src={images[i]}
            alt={`${i}`}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: handleImageChange,
  };
  return (
    <>
      <section className="merchandise">
        <div className="container content-merchandise">
          {!showCart && (
            <div className="container" style={{ paddingTop: "0px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-container">
                    <Slider
                      {...settings}
                      beforeChange={() => {
                        setZoomEnabled(false);
                      }}
                      afterChange={() => {
                        setZoomEnabled(true);
                      }}
                    >
                      {images.map((image, index) => (
                        <div key={index}>
                          <div
                            style={{
                              position: "relative",
                              width: "540px",
                              height: "306px",
                              overflow: "hidden",
                            }}
                          >
                            {zoomEnabled && (
                              <ReactImageZoom
                                img={image}
                                zoomScale={1}
                                width={540}
                                height={306}
                                isZoomed={false}
                                zoomPosition="original"
                                zoomType="hover"
                                cursorOnset={cursorPosition}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="col-lg-5">
                  <br />
                  <div>
                    <h3 className="d-block">
                      <strong>{produk.name_product}</strong>
                    </h3>
                    <div className="row">
                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: "13px" }}
                      >
                        <p
                          style={{
                            color: "grey",
                            marginRight: "10px",
                            marginBottom: "0",
                          }}
                        >
                          Made In Indonesia ||
                        </p>
                        <p
                          style={{
                            marginRight: "5px",
                            marginBottom: "0",
                          }}
                        >
                          Kondisi:
                        </p>
                        <p style={{ color: "black", marginBottom: "0" }}>
                          Baru
                        </p>
                      </div>
                    </div>
                    <br />
                    <h5>
                      <strong>{FormatRupiah(produk.price)}</strong>
                    </h5>
                    <br />
                    <h5>Deskripsi Produk</h5>
                    <hr />
                    <p style={{ textAlign: "justify" }}>
                      {produk.description || "Deskripsi tidak tersedia"}
                    </p>
                    <button
                      type="button"
                      className={`btn btn-success`}
                      style={{ marginTop: "7px", width: "120px" }}
                      onClick={() => handleAddToCart(produk)}
                    >
                      {inCart(produk?.id)
                        ? `In Cart (${
                            items.find((item) => item.id === produk.id)
                              ?.quantity || 0
                          })`
                        : "Add to Cart"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showCart && <Cart emptyCart={emptyCart} />}
        </div>
      </section>
    </>
  );
};

export default MerchandiseDetail;
