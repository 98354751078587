import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Swal from 'sweetalert2';

class Studytour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataStudyTour:[],
            dataKabupaten:[],
            dataBizDev:[],
            errors: [],
            detailRow: [],
            data_kegiatan: [
                {
                    'id': '1',
                    'kegiatan_name': 'Kursus Bahasa Inggris'
                },
                {
                    'id': '2',
                    'kegiatan_name': 'City Tour'
                },
                {
                    'id': '3',
                    'kegiatan_name': 'Kunjungan Sekolah/Kampus'
                },
                {
                    'id': '4',
                    'kegiatan_name': 'Partnership'
                },
                {
                    'id': '5',
                    'kegiatan_name': 'Short Course'
                },
                {
                    'id': '6',
                    'kegiatan_name': 'Aktivitas Lainnya'
                },
            ],
            modal: false,
            modal_itenary: false,
            nama: '',
            email: '',
            no_wa: '',
            kota_asal: '',
            negara_tujuan: '',
            tanggal_pergi: '',
            tanggal_pulang: '',
            total_peserta: '',
            total_pendamping: '',
            kode_bisdev: '',
            tipe_akomodasi: '',
            kegiatan: '',
            else_request: ''
        }
    }

    componentDidMount() {
        this.getStudyTour();
        this.getAllKabupaten();
        this.getBizdev();
    }

    getStudyTour() {
        axios.get(API_URL.superStudent+'/website/study-tour', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataStudyTour: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getAllKabupaten() {
        axios.get(API_URL.superStudent+'/kabupaten', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ dataKabupaten: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getBizdev(){
        const url = API_URL.superStudent+'/ambassador/consultant/jobdesk';
        let formData = new FormData ()
        formData.append('jobdesk', JSON.stringify(['JD007']))
        axios.post(url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.ambassadorKey}`
            }
        })
        .then(response => response.data.data)
        .then(result => this.setState({ dataBizDev: result }))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    saveRow() {
        let form = new FormData()
        form.append('nama', this.state.nama)
        form.append('email', this.state.email)
        form.append('no_wa', this.state.no_wa)
        form.append('kota_asal', this.state.kota_asal != '' ? this.state.kota_asal[0].id : '')
        form.append('negara_tujuan', this.state.negara_tujuan)
        form.append('tanggal_pergi', this.state.tanggal_pergi)
        form.append('tanggal_pulang', this.state.tanggal_pulang)
        form.append('total_peserta', this.state.total_peserta)
        form.append('total_pendamping', this.state.total_pendamping)
        form.append('kode_bisdev', this.state.kode_bisdev)
        form.append('tipe_akomodasi', this.state.tipe_akomodasi)
        form.append('kegiatan', JSON.stringify(this.state.kegiatan))
        form.append('else_request', this.state.else_request)

        axios.post(API_URL.eventUrl+'study-tour/register', form, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer `+API_URL.eventToken
            }
        })
        .then(response => {
            Swal.fire({
                icon: 'success',
                text: 'Register Success'
            }).then((result) => {
                window.location.reload()
            })
        })
        .catch(err => {
            if(err.response.status === 422 || err.response.status === 409) {
                this.setState({
                    errors: err.response.data.data
                })
            }
        })
    }

    booking() {
        this.setState({ modal: true })
    }

    itenary(row) {
        this.setState({ 
            modal_itenary: true,
            detailRow: row
        })
    }

    closeDialog() {
        this.setState({ 
            modal: false,
            modal_itenary: false 
        })
    }

    setKabupaten(e) {
        this.setState({ kota_asal: e })
    }

    setKegiatan = (e) => {
        this.setState({ kegiatan: e })
    }

    modalRegister() {
        const { modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })} style={{color: 'black'}}>Form Booking Study Tour</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="" style={{color: 'black'}}>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Nama </label>
                                    <input className="form-control" name="nama" type="text" id="nama" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.nama}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Email </label>
                                    <input className="form-control" name="email" type="text" id="email" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.email}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>No. Whatsapp </label>
                                    <input className="form-control" name="no_wa" type="text" id="no_wa" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.no_wa}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Kota Asal </label>
                                    <Typeahead
                                        id="basic-typeahead"
                                        labelKey="kabupaten_name"
                                        name="kabupaten_name"  
                                        options={this.state.dataKabupaten}
                                        onChange={(e) => this.setKabupaten(e)}
                                        placeholder="--Kota Asal--"
                                    />
                                    <span style={{color: 'red'}}>{this.state.errors.kota_asal}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Negara Tujuan </label>
                                    <input className="form-control" name="negara_tujuan" type="text" id="negara_tujuan" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.negara_tujuan}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Tanggal Pergi </label>
                                    <input className="form-control" name="tanggal_pergi" type="date" id="tanggal_pergi" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.tanggal_pergi}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Tanggal Pulang </label>
                                    <input className="form-control" name="tanggal_pulang" type="date" id="tanggal_pulang" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.tanggal_pulang}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Total Peserta </label>
                                    <input className="form-control" name="total_peserta" type="number" id="total_peserta" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.total_peserta}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Total Pendamping </label>
                                    <input className="form-control" name="total_pendamping" type="number" id="total_pendamping" onChange={(e) => this.handleInput(e)} />
                                    <span style={{color: 'red'}}>{this.state.errors.total_pendamping}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Recomender </label>
                                    <select className="form-control" name="kode_bisdev" type="number" id="kode_bisdev" onChange={(e) => this.handleInput(e)}>
                                        <option>--Pilih Recomender--</option>
                                        {
                                            this.state.dataBizDev.map((data)=>(
                                                <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                                            ))
                                        }
                                    </select>
                                    <span style={{color: 'red'}}>{this.state.errors.kode_bisdev}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Tipe Akomodasi </label>
                                    <select className='form-control' name="tipe_akomodasi" type="text" id="tipe_akomodasi" onChange={(e) => this.handleInput(e)}>
                                        <option value=''>--Pilih Akomodasi--</option>
                                        <option value='hotel'>Hotel</option>
                                        <option value='homestay'>Homestay</option>
                                    </select>
                                    <span style={{color: 'red'}}>{this.state.errors.tipe_akomodasi}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Kegiatan </label>
                                    <Typeahead
                                        id="basic-typeahead-multiple"
                                        labelKey="kegiatan_name"
                                        name="kegiatan_name"
                                        multiple 
                                        selected={this.state.kegiatan}
                                        options={this.state.data_kegiatan}
                                        onChange={(e) => this.setKegiatan(e)}
                                        placeholder="--Kegiatan--"
                                    />
                                    <span style={{color: 'red'}}>{this.state.errors.kegiatan}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Informasi Lainnya / Avalaible Budget </label>
                                    <textarea className="form-control" name="else_request" type="text" id="else_request" onChange={(e) => this.handleInput(e)} rows='3'></textarea>
                                    <span style={{color: 'red'}}>{this.state.errors.else_request}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveRow()}>Send</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    modalItenary() {
        const { modal_itenary, detailRow } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal_itenary} toggle={() => this.setState({ modal_itenary: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_itenary: false })} style={{color: 'black'}}>Form Booking Study Tour</ModalHeader>
                    <ModalBody>
                        <div className='row text-center'>
                            <div className='col-lg-12' style={{marginBottom: '10px'}}>
                                <div dangerouslySetInnerHTML={{__html:detailRow.itenary}}></div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {

        let rupiah = Intl.NumberFormat('id-ID');
        
        return (
            <div>
                <section className='bg-default'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <img className='img-fluid rounded float-right' src="../../../assets/img/slide/studytour/studytour.jpg"></img>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h1 className='text-center mb-5'><strong>Study Tour</strong></h1>
                        <div className='row mb-5 justify-content-center'>
                            {
                                this.state.dataStudyTour.map((detail, i) => {
                                    return  <div className='col-xl-4 col-sm-6 col-xs-12 mb-5'>
                                                <div className='card-study-tour-top text-center'>
                                                    <img className='img-fluid img-jurusan mb-3' src={API_URL.baseSuperStudent+"/website/studytour/"+detail.image}/>
                                                    <h4><strong>{detail.city}</strong></h4>
                                                    <h5><strong>{detail.county}</strong></h5>
                                                    <div className='card-study-tour-mid mb-4'>
                                                        <h6 className='m-1'><strong>{detail.durasi}</strong></h6>
                                                    </div>
                                                    <div className='card-study-tour-bottom'>
                                                        <h5 className='mt-4'><strong>Harga Mulai</strong></h5>
                                                        <div className='row text-center'>
                                                            <div className='col-lg-2'>
                                                                <h4><strong>Rp</strong></h4>
                                                            </div>
                                                            <div className='col-lg-9'>
                                                                <h2><strong>{rupiah.format(detail.harga)}/pax</strong></h2>
                                                            </div>
                                                            <h5><i>{detail.min_pax}</i></h5>
                                                        </div>
                                                        <Button className='btn-pesan mt-5 mb-5 ' onClick={() => this.itenary(detail)}>Itenary</Button> 
                                                    </div>
                                                    <div className='card-study-tour-footer p-2'>
                                                        <Button className='btn-pesan' onClick={() => this.booking()}>More Info</Button>
                                                    </div>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                        <h5 className='text-end mb-5'><strong>*Harga dapat berubah sewaktu – waktu, ditentukan dari rate dollar.</strong></h5>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container'>
                        <h1 className='text-center mb-5'><strong>Short Course</strong></h1>
                        <div className='card-study-tour-mid mb-4'>
                            <div className='row'>
                                <div className='col-lg-4 m-1'>
                                    <img className='img-fluid' src="../../../assets/img/studytour/english.jpg" />
                                </div>
                                <div className='col-lg-7 m-4'>
                                    <h2 className='text-start mb-3'><strong>English Course</strong></h2>
                                    <p className='text-justify mb-5'>
                                        <strong>
                                        Program pelatihan singkat ke luar negeri, dalam bentuk pelatihan Bahasa Inggris
                                        yang dipadukan dengan sistem homestay (tinggal bersama keluarga angkat di luar
                                        negeri). Bisa dalam bentuk group, maupun per orangan.
                                        </strong>
                                    </p>
                                    <a className='btn-pesan'>Pesan!</a>
                                </div>
                            </div>
                        </div>
                        <div className='card-study-tour-mid'>
                            <div className='row'>
                                <div className='col-lg-4 m-1'>
                                    <img className='img-fluid' src="../../../assets/img/studytour/general.jpg" />
                                </div>
                                <div className='col-lg-7 m-4'>
                                    <h2 className='text-start mb-3'><strong>General Course</strong></h2>
                                    <p className='text-justify mb-5'>
                                        <strong>
                                            Program pelatihan singkat ke luar negeri mulai dari program hospitality sampai
                                            leadership program.
                                        </strong>
                                    </p>
                                    <a className='btn-pesan'>Pesan!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.modalRegister()}
                {this.modalItenary()}
            </div>
        );

    }
}

export default (Studytour);