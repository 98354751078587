import React, { Component, Fragment } from 'react';

class CountryPOL extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-pol'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>POLANDIA</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                    Polandia adalah negara di central eropa yang memberikan fasilitas belajar kelas dunia dengan biaya yang sangat murah.
                                    Kuliah, Bekerja dan Tinggal di Polandia akan dapat memberikan akses bagi International Student untuk bisa mendapatkan kesempatan bekerja di seluruh eropa dengan investasi biaya kuliah yang sangat terjangkau
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>POLANDIA</strong></h1>
                                <h4><strong>Mengapa Polandia</strong></h4>
                                <p className='text-justify'>
                                    Qualitas pendidikan di Polandia di akui dunia dengan 12 Universitas nya masuk dalam jajaran peringkat universitas teratas dunia.
                                </p>
                                <p className='text-justify'>
                                    Selain itu, biaya kuliah dan biaya hidup di Polandia sangat lah murah sehingga kita bisa samakan biaya hidup dan kuliah nya yang hampir sama dengan biaya hidup di Jogjakarta - Indonesia.
                                </p>
                                <p className='text-justify'>
                                    Polandia juga menawarkan banyak keragaman budaya karena lokasinya yang berada di central eropa sehingga memudahkan setiap orang yang tinggal di Polandia untuk bisa travelling ke negara negara tetangga di eropa. Bayangkan saja, kamu bisa makan pagi di di Polandia, makan siang di german dan makan malan di ceko dalam 1 hari.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE COLLEGE</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>SWASTA</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>PEMERINTAH</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1,5 - 2,5 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>500 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>200 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>10 - 15 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Polandia</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-6'>
                                <img className='img-fluid mb-3' src="../../assets/img/country/POL/warsawa.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Warsawa</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img className='img-fluid mb-3' src="../../assets/img/country/POL/poznan.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Poznan</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Polandia</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/POL/warsawa.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Warsawa Old Town</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/POL/krakow-square.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Krakow Central Square</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/POL/giewont.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Giewont Mountain</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/POL/wroclaw.jpeg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Rynek of Wroclaw</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryPOL);