import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

class CampusEcu extends Component {

    render() {
        
        return (
            <div>
                <section className='bg-abu'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <img className='img-fluid rounded float-right' src="../../../assets/img/slide/ecu/slide-ecu.jpg"></img>
                        </div>
                    </div>
                </section>
                <div className='bg-abu mb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-9 text-start'>
                                <h4 className='mb-3'><strong>Australia / Perth - Western Australia</strong></h4>
                                <h1 className='mb-3'><strong>Edith Cowan University</strong></h1>
                                <h5 className='mb-3'>270 Joondalup Dr, Joondalup WA 6027, Australia</h5>
                            </div>
                            <div className='col-lg-3 text-end'>
                                <img className='img-fluid' src="../../assets/img/clients/campus-03.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <main id="main">
                    <div class="container mb-5">
                        <div className='row'>
                            <div className='col-lg-6 text-start'>
                                <p className='text-justify'>
                                    Terletak di Perth, Western Australia dan meraih peringkat
                                    nomor 6 di kota paling layak huni di dunia, Edith Cowan
                                    University menawarkan lingkungan belajar yang ramah,
                                    inklusif, dan aman bagi siswa. Universitas ini didirikan pada
                                    tahun 1991 dan membentuk kembali pendidikan tinggi
                                    berkualitas dalam sebuah lingkungan kampus yang khas
                                    dan menginspirasi.
                                </p>
                                <br/>
                                <p className='text-justify'>
                                    Kombinasi ECU dalam pengajaran serta penelitian yang
                                    relevan dengan industri, lingkungan belajar yang
                                    mendukung, dan fasilitas yang berhasil mendapatkan
                                    penghargaan mampu membuat siswa lebih berkembang
                                    di dunia yang berubah dengan pesat ini. Program mereka
                                    dikembangkan melalui konsultasi dengan industri, dan
                                    staf pengajar profesionalnya memiliki pengalaman hingga
                                    jaringan industri yang luas. Siswa ECU akhirnya
                                    berkesempatan untuk mendapatkan peluang
                                    penempatan, kerja lapangan, praktikum dan acara
                                    networking sebagai bagian dari studi mereka. Bentuk
                                    pendekatan ini bahkan meraih penghargaan bintang lima
                                    untuk kualitas pengajaran selama tiga belas tahun
                                    berturut-turut dalam Good Universities Guide. Hanya
                                    sedikit universitas yang pernah berhasil mencapai
                                    peringkat ini.
                                </p>
                            </div>
                            <div className='col-lg-6 text-end'>
                                <div className='container'>
                                    <div className='row card-promo-campus-top'>
                                        <div className='col-lg-6'>
                                            <h3>Edith Cowan University</h3>
                                            <p>*Hanya Beberapa Jurusan</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h2>Promo Type</h2>
                                        </div>
                                    </div>
                                    <div className='row text-start card-promo-campus-middle'>
                                        <div className='col-lg-12'>
                                            <h4 className='mb-3'><strong>2022 International Undergraduate Scholarship ECU</strong></h4>
                                            <h5>Beasiswa Edith Cowan ini ditawarkan kepada pelajar internasional dari negara-negara tertentu yang menginginkan gelar Sarjana di ECU.</h5>
                                        </div>
                                    </div>
                                    <div className='row text-start card-promo-campus-bottom'>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-3 text-center'>
                                                    <h1><i class="bi bi-calendar-check"></i></h1>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <p>Berlaku Sampai</p>
                                                    <p>31 September 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-3 text-center'>
                                                    <h1><i class="bi bi-cash-coin"></i></h1>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <p>Value</p>
                                                    <i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container mb-5">
                        <Tabs defaultActiveKey="keunggulan" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="keunggulan" title="Keunggulan">
                                <h2>Keunggulan</h2>
                            </Tab>
                            <Tab eventKey="kiaya" title="Biaya">
                                <h2>Biaya</h2>
                            </Tab>
                        </Tabs>
                    </div>
                    <div class="container mb-5 text-center">
                        <h1 className='text-center mb-5'><strong>Beberapa Jurusan yang masuk Top Dunia</strong></h1>
                        <h4 className='text-center mb-5'>Terdapat ratusan jurusan untuk program S1, S2, S3 dengan program unggulan</h4>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/jurusan/ecu/bisnis.jpg"/>
                                <h3>Bisnis & Manajemen</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/jurusan/ecu/it.jpg"/>
                                <h3>IT</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/jurusan/ecu/teknik.jpg"/>
                                <h3>Teknik (Engineering)</h3>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/jurusan/ecu/psikologi.jpg"/>
                                <h3>Psikologi</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/jurusan/ecu/pendidikan.jpg"/>
                                <h3>Pendidikan</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/jurusan/ecu/kesehatan.jpg"/>
                                <h3>Kesehatan</h3>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );

    }
}

export default (CampusEcu);