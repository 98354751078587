import React, { useState  } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {

    const[show,setShow] = useState('none');
    const[service,setService] = useState('none');
    const[abroad,setAbroad] = useState('none');
    const[event,setEvent] = useState('none');

    const enableDropDown = () => {
        setShow('block');
    }

    const disableDropDown = () => {
        setShow('none');
    }

    const dropService = () => {
        if (service == 'none') {
            setService('block')
        } else {
            setService('none')
        }
    }

    const dropAbroad = () => {
        if (abroad == 'none') {
            setAbroad('block')
        } else {
            setAbroad('none')
        }
    }

    const dropEvent = () => {
        if (event == 'none') {
            setEvent('block')
        } else {
            setEvent('none')
        }
    }

    return (
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
                <a href='https://niecindonesia.com/'><img class="logo me-auto" height="60" src="../../assets/img/logo/logo-01.png"></img></a>
                <div className='container'>
                    <div className='row text-end'>
                        <div className='col-lg-12'>
                            <div class="badge rounded-pill bg-dark text-white text-end">
                                <h4 ><a className='font-basic' href="https://wa.me/6289527440126"><i className="bi bi-headset"></i> +62 895-2744-0126</a></h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <nav id="navbar" class="navbar">
                                <ul>
                                    <li><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/about`}>About</Link></li>
                                    <li class="dropdown"><Link><span>Our Services</span> <i class="bi bi-chevron-down"></i></Link>
                                        <ul>
                                            <li class="dropdown"><a><span>Study Abroad</span> <i class="bi bi-chevron-left"></i></a>
                                                <ul>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/aus`}>Australia</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/can`}>Canada</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/pol`}>Polandia</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/kor`}>Korea</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/jpn`}>Japan</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/usa`}>USA</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/tur`}>Turkey</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/nzl`}>Selandia Baru</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/che`}>Swiss</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/ger`}>Jerman</Link></li>
                                                    <li><Link to={`${process.env.PUBLIC_URL}/country/hun`}>Hungaria</Link></li>
                                                </ul>
                                            </li>
                                            <li><a class="active" href='https://superstudent.niecindonesia.com/simcard/' target="_blank">SIM Card Canada</a></li>
                                            <li><a class="active" href='http://internship.niecindonesia.com/' target="_blank">Intership</a></li>
                                            <li><a class="active" href='http://studytour.niecindonesia.com/' target="_blank">Study Tour</a></li>
                                            <li><a class="active" href='http://kursusbahasa.niecindonesia.com/' target="_blank">Kursus Bahasa</a></li>
                                            <li><a class="active" href='http://prioritas.niecindonesia.com/' target="_blank">Tallent Mapping</a></li>
                                            <li><a class="active" href='https://superstudent.niecindonesia.com/accomodation' target="_blank">Accomodation</a></li>
                                        </ul>
                                    </li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/scholarship`}>Scholarship</Link></li>
                                    <li class="dropdown"><Link><span>Event</span> <i class="bi bi-chevron-down"></i></Link>
                                        <ul>
                                            <li><a class="active" href='http://edufest.niecindonesia.com/' target="_blank">World Education Festival</a></li>
                                            <li><a class="active" href='https://infosession.niecindonesia.com/' target="_blank">Info Session</a></li>
                                            <li><a class="active" href='https://youthdev.niecindonesia.com/' target="_blank">Global Youth</a></li>
                                        </ul>
                                    </li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/contact_us`}>Contact Us</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/career`}>Career</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/merchandise`}>Merchandise</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/artikel`}>News</Link></li>
                                </ul>
                                <i class="bi bi-list mobile-nav-toggle" onClick={enableDropDown}></i>
                            </nav>
                            <nav style={{display: show}} className='navbar-mobile'>
                                <ul>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></a></li>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/about`}>About</Link></a></li>
                                    <li class="dropdown"><a onClick={dropService}><Link><span>Our Services</span> <i class="bi bi-chevron-down"></i></Link></a></li>
                                    <div style={{paddingLeft: '15px', display: service}}>
                                        <li class="disable-dot dropdown"><a onClick={dropAbroad}><Link><span>Study Abroad</span> <i class="bi bi-chevron-down"></i></Link></a></li>
                                            <div style={{paddingLeft: '15px', display: abroad}}>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/aus`}>Australia</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/can`}>Canada</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/pol`}>Polandia</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/kor`}>Korea</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/jpn`}>Japan</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/usa`}>USA</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/tur`}>Turkey</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/nzl`}>Selandia Baru</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/che`}>Swiss</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/ger`}>Jerman</Link></a></li>
                                                <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/country/hun`}>Hungaria</Link></a></li>
                                            </div>
                                        <li className='disable-dot'><a href='https://superstudent.niecindonesia.com/simcard/' target="_blank" onClick={disableDropDown}><Link>Sim Card Canada</Link></a></li>
                                        <li className='disable-dot'><a href='http://internship.niecindonesia.com/' target="_blank" onClick={disableDropDown}><Link>Intership</Link></a></li>
                                        <li className='disable-dot'><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/study_tour`}>Study Tour</Link></a></li>
                                        <li className='disable-dot'><a href='http://kursusbahasa.niecindonesia.com/' target="_blank" onClick={disableDropDown}><Link>Kursus Bahasa</Link></a></li>
                                        <li className='disable-dot'><a href='http://prioritas.niecindonesia.com/' target="_blank" onClick={disableDropDown}><Link>Tallent Mapping</Link></a></li>
                                        <li className='disable-dot'><a href='https://superstudent.niecindonesia.com/accomodation/' target="_blank" onClick={disableDropDown}><Link>Accomodation</Link></a></li>
                                    </div>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/scholarship`}>Scholarship</Link></a></li>
                                    <li class="dropdown"><a onClick={dropEvent}><Link><span>Event</span> <i class="bi bi-chevron-down"></i></Link></a></li>
                                    <div style={{paddingLeft: '15px', display: event}}>
                                        <li className='disable-dot'><a href='http://edufest.niecindonesia.com/' target="_blank" onClick={disableDropDown}><Link>World Education Festival</Link></a></li>
                                        <li className='disable-dot'><a href='https://infosession.niecindonesia.com/' target="_blank" onClick={disableDropDown}><Link>Info Session</Link></a></li>
                                        <li className='disable-dot'><a href='https://youthdev.niecindonesia.com/' target="_blank" onClick={disableDropDown}><Link>Global Youth</Link></a></li>
                                    </div>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/contact_us`}>Contact Us</Link></a></li>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/career`}>Career</Link></a></li>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/merchandise`}>Merchandise</Link></a></li>
                                    <li><a onClick={disableDropDown}><Link to={`${process.env.PUBLIC_URL}/artikel`}>Artikel</Link></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;