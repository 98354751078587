import React, { Component, Fragment } from 'react';

class CountryHUN extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-hun'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>Hungaria</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                        Kuliah di Hungaria tidak hanya biaya kuliah yang sangat terjangkau tapi juga biaya hidup yang sangat hemat di bandingkan dengan berbagai negara lainnya yang menawarkan qualitas pendidikan yang sama
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>Hungaria</strong></h1>
                                <h4><strong>Mengapa Hungaria</strong></h4>
                                <p className='text-justify'>
                                    Hungaria adalah negara dengan biaya kuliah dan biaya hidup yang sangat terjangkau. Qualitas pendidikan hungaria mengikuti dengan European Standart dan Pendidikan Kedokteran mereka di kenal dengan sebagai salah satu yang terbaik di Eropa. 
                                    Hungaria adalah negara yang di kenal sangat aman. Mereka menempati peringkat ke 15 dari 165 negara di dunia dalam Global Peace Index. 
                                    Tinggal dan study di Hungaria 70% lebih murah daripada study di USA. Keunikan lain ketika kamu study di Hungaria, kamu bisa travelling ke seluruh eropa dengan mudah karena Hungaria berada di central europe. 
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Pemerintah & Swasta</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>500 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1,5 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong></strong></h3>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>200 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>10 - 15 Juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Hungaria</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/HUN/bg-hun-02.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Budapest</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/HUN/bg-hun-03.jpeg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Pecs</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Hungaria</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/HUN/bg-hun-05.png"/>
                                <div className="card-jurusan container">
                                    <h3><strong>The parliament Building Hungaria</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/HUN/bg-hun-04.jpeg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Mattias Church</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/HUN/bg-hun-06.jpeg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Hungarian State Opera House</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryHUN);