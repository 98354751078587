import React, { Component } from 'react'
import axios from 'axios';
import API_URL from '../../ApiUrl';
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

export class DetailLowongan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnLoading: false,
            btnDisabled: false,
            first_name: '',
            last_name: '',
            email: '',
            no_hp: '',
            place_birth: '',
            date_birth: '',
            gender: '',
            maried: '',
            religion: '',
            address: '',
            id_current_province: '',
            id_ktp_province: '',
            current_province: '',
            current_city: '',
            current_subdistrict: '',
            current_kode_post: '',
            ktp_addres: '',
            ktp_province: '',
            ktp_city: '',
            ktp_subdistrict: '',
            ktp_kode_post: '',
            instagram: '',
            linkedin: '',
            emergency_name: '',
            emergency_phone: '',
            file_cv: '',
            file_cv_name: '',
            file_ijazah: '',
            file_ijazah_name: '',
            file_english: '',
            file_english_name: '',
            text_promosi_diri: '',
            file: [],
            captcha: '',
            errors: [],
            provinsi: [],
            kabkotaCurrent: [],
            kabkotaKtp: [],
        }
    }

    handleCaptcha(val){
        this.setState({captcha: val})
    }

    handleChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleFileIjazah(e){
        const {files} = e.target
        this.setState({file_ijazah: files[0], file_ijazah_name: files[0].name})
    } 

    handleFileCV(e){
        const {files} = e.target
        this.setState({file_cv: files[0], file_cv_name: files[0].name})
    }

    handleFileEnglish(e){
        const {files} = e.target
        this.setState({file_english: files[0], file_english_name: files[0].name})
    }

    handleChangeProvince(e, type){{
        const {name, value} = e.target;
        const all_province = Object.values(this.state.provinsi);
        const filter_province = all_province.filter(filter => {
            return filter.id == value;
        });
        type == 'current' ? 
        this.setState({
            [name]: value,
            current_province: filter_province[0].province_name,
        }, () => {
            this.getKabkotaByProvince(value, type)
        })
        :
        this.setState({
            [name]: value,
            ktp_province: filter_province[0].province_name
        }, () => {
            this.getKabkotaByProvince(value, type)
        });
    }}

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    applyKandidat(e){
        e.preventDefault();
        this.setState({btnLoading: true, btnDisabled: true})
        const { data_lowongan } = this.state;

        let formData = new FormData();

        formData.append('first_name', this.state.first_name) 
        formData.append('last_name', this.state.last_name) 
        formData.append('job_vacancy', data_lowongan?.title)
        formData.append('id_job_vacancy', data_lowongan?.id_job_vacancy)
        formData.append('email', this.state.email)
        formData.append('no_hp', this.state.no_hp)
        formData.append('place_birth', this.state.place_birth)
        formData.append('date_birth', this.state.date_birth)
        formData.append('gender', this.state.gender)
        formData.append('maried', this.state.maried)
        formData.append('religion', this.state.religion)
        formData.append('address', this.state.address)
        formData.append('current_province', this.state.current_province)
        formData.append('current_city', this.state.current_city)
        formData.append('current_subdistrict', this.state.current_subdistrict)
        formData.append('current_kode_post', this.state.current_kode_post)
        formData.append('ktp_addres', this.state.ktp_addres)
        formData.append('ktp_province', this.state.ktp_province)
        formData.append('ktp_city', this.state.ktp_city)
        formData.append('ktp_subdistrict', this.state.ktp_subdistrict)
        formData.append('ktp_kode_post', this.state.ktp_kode_post)
        formData.append('instagram', this.state.instagram)
        formData.append('linkedin', this.state.linkedin)
        formData.append('emergency_name', this.state.emergency_name)
        formData.append('emergency_phone', this.state.emergency_phone)
        formData.append('text_promosi_diri', this.state.text_promosi_diri)

        formData.append('file_cv', this.state.file_cv)
        formData.append('file_cv_name', this.state.file_cv_name)
        
        formData.append('file_ijazah', this.state.file_ijazah)
        formData.append('file_ijazah_name', this.state.file_ijazah_name)
        
        formData.append('file_english', this.state.file_english)
        formData.append('file_english_name', this.state.file_english_name)

        axios.post(API_URL.superStudent2 + '/niec-career-candidate/add', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(res => {
            Swal.fire({
                title: 'Berkas Lamaran Anda Telah Terkirim',
                icon: 'success',
                type: 'success',
                confirmButtonColor: '#3085d6', 
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                    this.setState({btnLoading: false, btnDisabled: false})
                }
            })
        })
        .catch(err=>{
            this.setState({errors: err.response.data.data, btnLoading: false, btnDisabled: false})
            console.log(err.response.data.data)
        })
    }

    getDetailLowongan(){
        axios.get(API_URL.superStudent2 + '/niec-career/detail-lowongan/' + this.props.match.params.slug)
        .then(response => this.setState({ data_lowongan: response.data.data}))
        .catch(error => console.log(error))
    }

    getProvinsi(){
        axios.get(API_URL.superStudent2 + '/master/provinces')
        .then(response => this.setState({ provinsi: response.data.data}))
        .catch(error => console.log(error))
    }

    getKabkotaByProvince(id_province, type){
        axios.get(API_URL.superStudent2 + '/master/kabupaten/' + id_province)
        .then(response => response.data.data)
        .then(data => {
            if (type == 'current') {
                this.setState({
                    kabkotaCurrent: data
                })
            } else {
                this.setState({
                    kabkotaKtp: data
                })
            }
        })
        .catch(error => console.log(error))
    }

    componentDidMount(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.getDetailLowongan();
        this.getProvinsi();
    }

    render() {
        const { data_lowongan, errors } = this.state
        return (
            <>
                <section className='career'>
                    <div className="bg-header detail-job">
                        <h4 className='job-title'>{data_lowongan?.title}</h4>
                    </div>
                    <div className="container content-detail-lowongan">
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <h1>{data_lowongan?.title}</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <p className='kat'>
                                    <i class="bi bi-briefcase"></i> {data_lowongan?.department} <span>|</span> <i class="bi bi-geo-alt"></i> {data_lowongan?.internal_area.area} 
                                    {data_lowongan?.gaji_1 === null ? '' : 
                                    (<><span>|</span> <i className='bi bi-currency-exchange'></i> {this.formatRupiah(data_lowongan?.gaji_1)} </>)}
                                    {data_lowongan?.gaji_2 === null ? '' : '- ' + this.formatRupiah(data_lowongan?.gaji_2)}
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-12 d-flex justify-content-center">
                                <div dangerouslySetInnerHTML={{__html: data_lowongan?.deskripsi}} />
                            </div>
                        </div>
                        <div className='row mt-5 d-flex justify-content-center'>
                            <div className="col-lg-11">
                                <div className="form-card">
                                    <h4>Apply Your Resume</h4>
                                    <form onSubmit={(e) => this.applyKandidat(e)}>
                                        <div className='row'>
                                            <div className='col'>
                                                <span>First Name</span>
                                                <input type="text" name='first_name' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.first_name ? 'is-invalid' : ''}`} id="first_name" />
                                                <div class="invalid-feedback">{errors?.first_name}</div>
                                            </div>
                                            <div className='col'>
                                                <span>Last Name</span>
                                                <input type='text' name='last_name' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.last_name ? 'is-invalid' : ''}`} id="last_name" />
                                                <div class="invalid-feedback">{errors?.last_name}</div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col'>
                                                <span>Phone Number (WA)</span>
                                                <input type='number' name='no_hp' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.no_hp ? 'is-invalid' : ''}`} id="no_hp" />
                                                <div class="invalid-feedback">{errors?.no_hp}</div>
                                            </div>
                                            <div className='col'>
                                                <span>Email</span>
                                                <input type='text' name='email' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.email ? 'is-invalid' : ''}`} id="email" />
                                                <div class="invalid-feedback">{errors?.email}</div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col'>
                                                <span>Place Birth</span>
                                                <input type='text' name='place_birth' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.place_birth ? 'is-invalid' : ''}`} id="place_birth" />
                                                <div class="invalid-feedback">{errors?.place_birth}</div>
                                            </div>
                                            <div className='col'>
                                                <span>Date Birth</span>
                                                <input type='date' name='date_birth' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.date_birth ? 'is-invalid' : ''}`} id='date_birth' />
                                                <div class="invalid-feedback">{errors?.date_birth}</div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-3'>
                                                <span>Gender</span>
                                                <select name='gender' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.gender ? 'is-invalid' : ''}`} id='gender'>
                                                    <option value={''}>--Choose One--</option>
                                                    <option value={'L'}>Men</option>
                                                    <option value={'P'}>Woman</option>
                                                </select>
                                                <div class="invalid-feedback">{errors?.gender}</div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <span>Maried</span>
                                                <select name='maried' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.maried ? 'is-invalid' : ''}`} id='maried'>
                                                    <option value={''}>--Choose One--</option>
                                                    <option value={'S'}>Single</option>
                                                    <option value={'M'}>Maried</option>
                                                </select>
                                                <div class="invalid-feedback">{errors?.maried}</div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <span>Religion</span>
                                                <select name='religion' onChange={(e) => this.handleChange(e)} className='form-control' id='religion'>
                                                    <option>--Choose One--</option>
                                                    <option value={'1'}>Moeslem</option>
                                                    <option value={'2'}>Khatolik</option>
                                                    <option value={'3'}>Christian</option>
                                                    <option value={'4'}>Hindi</option>
                                                    <option value={'5'}>Buddha</option>
                                                    <option value={'6'}>Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <span>Current Address</span>
                                                <textarea name='address' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.address ? 'is-invalid' : ''}`} style={{height: '110px', resize: 'none'}} id='address'></textarea>
                                                <div class="invalid-feedback">{errors?.address}</div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <span>Province</span>
                                                        <select name='id_current_province' onChange={(e) => this.handleChangeProvince(e, 'current')} className={`form-control ${errors?.id_current_province ? 'is-invalid' : ''}`} id='id_current_province'>
                                                            <option value={''}>--Choose One--</option>
                                                            {
                                                                this.state.provinsi.map((provin) => (
                                                                    <option value={provin.id}>{provin.province_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div class="invalid-feedback">{errors?.current_province}</div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span>City</span>
                                                        <select name='current_city' onChange={(e) => this.handleChange(e)} className={`form-control ${errors?.current_city ? 'is-invalid' : ''}`} id='current_city'>
                                                            <option value={''}>--Choose One--</option>
                                                            {
                                                                this.state.kabkotaCurrent.map((kabkota) => (
                                                                    <option value={kabkota.kabupaten_name}>{kabkota.kabupaten_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div class="invalid-feedback">{errors?.current_city}</div>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-lg-6'>
                                                        <span>Subdistrict</span>
                                                        <input name='current_subdistrict' className='form-control' type='text' onChange={(e) => this.handleChange(e)} id='kecamatan'  />
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span>Post Code</span>
                                                        <input name='current_kode_post' className='form-control' type='text' onChange={(e) => this.handleChange(e)} id='current_kode_post' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <span>ID Card Address</span>
                                                <textarea name='ktp_addres' onChange={(e) => this.handleChange(e)} className='form-control' style={{height: '110px', resize: 'none'}}></textarea>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <span>Province</span>
                                                        <select name='id_ktp_province' onChange={(e) => this.handleChangeProvince(e, 'ktp')}  className='form-control' id='id_ktp_province'>
                                                            <option value={''}>--Choose One--</option>
                                                            {
                                                                this.state.provinsi.map((provin) => (
                                                                    <option value={provin.id}>{provin.province_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span>City</span>
                                                        <select name='ktp_city' onChange={(e) => this.handleChange(e)} className='form-control' id='ktp_city'>
                                                            <option value={''}>--Choose One--</option>
                                                            {
                                                                this.state.kabkotaKtp.map((kabkota) => (
                                                                    <option value={kabkota.kabupaten_name}>{kabkota.kabupaten_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-lg-6'>
                                                        <span>Subdistrict</span>
                                                        <input name='ktp_subdistrict' type='text' onChange={(e) => this.handleChange(e)} className='form-control' id='ktp_subdistrict' />
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <span>Post Code</span>
                                                        <input name='ktp_kode_post' type='text' onChange={(e) => this.handleChange(e)} className='form-control' id='ktp_kode_post' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <span>Instagram (Username / Link)</span>
                                                <input name='instagram' type='text' onChange={(e) => this.handleChange(e)} className='form-control' id='instagram' />
                                            </div>
                                            <div className='col-lg-6'>
                                                <span>Linkedin (Username / Link)</span>
                                                <input name='linkedin' type='text' onChange={(e) => this.handleChange(e)} className='form-control' id='linkedin' />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <span>Emergency Contact (Name)</span>
                                                <input name='emergency_name' type='text' onChange={(e) => this.handleChange(e)} className='form-control' id='emergency_name' />
                                            </div>
                                            <div className='col-lg-6'>
                                                <span>Emergency Contact (Phone / WA)</span>
                                                <input name='emergency_phone' type='text' onChange={(e) => this.handleChange(e)} className='form-control' id='emergency_phone' />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-4'>
                                                <span>Upload Certivicate</span>
                                                <input type="file" name='file_ijazah' onChange={(e) => this.handleFileIjazah(e)} className={`form-control ${errors?.file_ijazah ? 'is-invalid' : ''}`} />
                                                <div class="invalid-feedback">{errors?.file_ijazah}</div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <span>Upload CV</span>
                                                <input type="file" name='file_cv' onChange={(e) => this.handleFileCV(e)} className={`form-control ${errors?.file_cv ? 'is-invalid' : ''}`} id="file_cv" placeholder='File CV' />
                                                <div class="invalid-feedback">{errors?.file_cv}</div>
                                            </div>
                                            <div className='col-lg-4'>
                                                <span>Upload Englist Test</span>
                                                <input type="file" name='file_english_test' onChange={(e) => this.handleFileEnglish(e)} className="form-control" id="file_english_test" placeholder='File English Test' />
                                                <div class="invalid-feedback">{errors?.file_english_test}</div>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <span>Describe Your Self</span>
                                                <textarea name="text_promosi_diri" className='form-control' id="" rows={5} onChange={(e) => this.handleChange(e)}></textarea>
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <ReCAPTCHA
                                                    sitekey="6LcDiyIpAAAAAEUwQ9U7iOxiJKVO53AFx3qHN1HK"
                                                    onChange={(val)=>this.handleCaptcha(val)}
                                                />
                                            </div>
                                            <div className='col-lg-6 d-flex justify-content-end'>
                                                <button disabled={(this.state.btnDisabled && true) || (!this.state.captcha && true)} className='btn btn-success mt-3'>{this.state.btnLoading ? 'Applying...' : 'Apply'}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default DetailLowongan