import React, { Component, Fragment } from 'react';

class CountryNZL extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-nzl'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>New Zealand</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                    New zealand adalah negara yang di kenal dengan orang orang yang ramah, lahan yang sangat indah dan gaya hidup yang santai.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>NEW ZEALAND</strong></h1>
                                <h4><strong>Mengapa New Zealand</strong></h4>
                                <p className='text-justify'>
                                    New zealand adalah negara yang sangat Indah sehingga membuat kuliah di new zealand akan sangat nyaman. Dengan tingkat kriminal yang sangat rendah serta focus pendidikan mereka pada “well being” dari setiap students membuat study di New Zealand menjadi pengalaman yang sangat menyenangkan.
                                </p>
                                <p className='text-justify'>
                                    New Zealand juga sangat cocok untuk mahasiswa PhD karena biaya kuliah yang sangat murah untuk siswa PhD dan dukungan penelitian yang sangat tinggi. Bagi siswa yang sudah berkeluarga, new zealand juga memungkinkan Mahasiswa untuk membawa keluarganya dan mendapatkan free education bagi anak anak nya.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-4'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>COLLEGE</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>Praktek</h5></tr>
                                        <tr><h5>Diploma s/d S-1</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-4'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>Teori</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-4'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>POLYTECHNIC</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>Praktek</h5></tr>
                                        <tr><h5>Diploma</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1 juta / Minggu</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>500 ribu / Minggu</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>100 ribu / Minggu</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>150 ribu / jam</strong></h2>
                                </div>
                                <h3><strong></strong></h3>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of New Zealand</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/auckaland2.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Auckaland</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/Chrischurch.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Chrischurch</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/queenstown.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Queenstown</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/Wellington.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Wellington</strong></h3>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in New Zealand</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/milfoldzound.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Milford Sound</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/mouth.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Mouth Cook</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/hobbit.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Hobbiton Movie Set Tour</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/NZL/waitomo.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Waitomo Cafe</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryNZL);