import React, { Component, Fragment } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import Countup from 'react-countup';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalStudents:0,
            totalMembers:0,
            totalAmbassador:0,
            totalUniversity:0,
            totalYears: Moment().format('YYYY') - 2010,
            totalPlatinum: 0,
            platinum: []
        }
    }

    componentDidMount() {
        this.getStudentsAll();
        this.getMembersAll();
        this.getAmbassadorAll();
        this.getUniversity();
        this.getPlatinum();
    }

    getStudentsAll() {
        var formData = new FormData();
        formData.append('start_date', '');
        formData.append('end_date', '');

        axios.post(API_URL.superStudent+'/api/v1/application/student/student-total', formData, {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data)
        .then(result => {
            this.setState({ totalStudents: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getMembersAll() {
        axios.get(API_URL.superStudent+'/members/total-members?status_payment=Y', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ totalMembers: result[0] })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getAmbassadorAll() {
        axios.get(API_URL.superStudent+'/ambassador/countAll', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ totalAmbassador: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getUniversity() {
        axios.get(API_URL.superStudent+'/university/all', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ totalUniversity: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getPlatinum() {
        axios.get(API_URL.superStudent+'/university/platinum', {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({ platinum: result })
        })
        .catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
            <div>
                <section className='banner-lg'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-5' style={{marginTop: '300px'}}>
                                <h3><strong>NIEC INDONESIA</strong></h3>
                                <h1>Education</h1>
                                <h1>Consultant</h1>
                                <p className='text-justify'>
                                    Konsultan Pendidikan dengan pengalaman {this.state.totalYears} tahun,
                                    telah membantu ribuan anak Indonesia kuliah
                                    ke luar negeri
                                </p>
                            </div>
                            <div className='col-lg-7' style={{marginTop: '200px'}}>
                                <div className='text-center'>
                                    <img className='img-fluid rounded float-right' src="../../assets/img/slide/home/Header-04.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-lg-12">
                                    <h2>Mengapa</h2>
                                    <h2>Kuliah di Luar Negeri?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 mb-4">
                                    <div className="card text-center bg-off">
                                        <i className="bi bi-mortarboard"></i>
                                        <h4>Mendapatkan
                                            <br />Pendidikan Dengan
                                            <br />Kualitas Terbaik
                                        </h4>
                                        <p>Bisa Memperoleh Kualitas Pendidikan Dengan Standar Internasional di Berbagai Mancanegara</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="card text-center bg-off">
                                        <i className="bi bi-briefcase"></i>
                                        <h4>Jangkauan
                                            <br />Karir dan Relasi yang
                                            <br />Lebih Luas
                                        </h4>
                                        <p>Berkesempatan Bisa Berkarir dan Berelasi Dengan Cakupan Internasional</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="card text-center bg-off">
                                        <i className="bi bi-cash-coin"></i>
                                        <h4>Dapat Bekerja
                                            <br />Partime Sambil
                                            <br />Kuliah
                                        </h4>
                                        <p>Bisa Memiliki Penghasilan Tambahan Dengan Mudah </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-light">
                        <div className="container">
                            <div className="row mb-5">
                                <div className='col-lg-8'>
                                    <h2>{this.state.totalYears} Tahun Kepercayaan dan</h2>
                                    <h2>Rekomendasi Siswa</h2>
                                    <h2>Untuk Konsultasi Kuliah Luar Negeri</h2>
                                    <br />
                                    <h5>Rising Star Agent 2021 by ECU</h5>
                                    <h5>Finalist Education Agency of The Year 2018 by PIEoneer Award</h5>
                                </div>
                                <div className='col-lg-4'>
                                    <img height="200" src="../../assets/img/Icon-01.png" />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-3 text-center icon-total mb-5'>
                                    <div className="card border border-2 border-dark">
                                        <img className='img-fluid-icon img-center' src="../../assets/img/icon/icon-01.png"/>
                                        <h1><Countup end={this.state.totalMembers} /></h1>
                                        <h3>Members</h3>
                                    </div>
                                </div>
                                <div className='col-lg-3 text-center icon-total mb-5'>
                                    <div className="card border border-2 border-dark">
                                        <img className='img-fluid-icon img-center' src="../../assets/img/icon/icon-02.png"/>
                                        <h1><Countup end={this.state.totalAmbassador} /></h1>
                                        <h4>Ambassador</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 text-center icon-total mb-5'>
                                    <div className="card border border-2 border-dark">
                                        <img className='img-fluid-icon img-center' src="../../assets/img/icon/icon-03.png"/>
                                        <h1><Countup end={this.state.totalStudents} /></h1>
                                        <h4>Students</h4>
                                    </div>
                                </div>
                                <div className='col-lg-3 text-center icon-total mb-5'>
                                    <div className="card border border-2 border-dark">
                                        <img className='img-fluid-icon img-center' src="../../assets/img/icon/icon-04.png"/>
                                        <h1><Countup end={this.state.totalUniversity} /></h1>
                                        <h4>School Partner</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='bg-danger globe-width'>
                        <div className='row text-white'>
                            <div className='col-lg-6'>
                                <img src="../../assets/img/niec-globe-4.png" class="img-fluid-globe" data-angle="0" />
                            </div>
                            <div className='col-lg-6'>
                                <h1>Destinasi</h1>
                                <h1 className='mb-3'>Pilihan Negara</h1>
                                <Carousel
                                    interval={null}
                                    indicators={false} >
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/aus`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/AUS/Country-02.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/can`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/CAN/Country-03.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/pol`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/POL/Country-04.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/kor`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/KOR/Country-05.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/jpn`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/JPN/Country-06.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/usa`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/USA/Country-01.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/tur`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/TUR/Country-07.png"></img></a></Link>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Link to={`${process.env.PUBLIC_URL}/country/nzl`}><a><img className='img-fluid-icon img-center' src="../../assets/img/country/NZL/Country-08.png"></img></a></Link>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-6 text-left'>
                                    <h1>TOP {this.state.platinum.length} Kampus</h1>
                                    <h1 className='mb-5'>Pilihan</h1>
                                    <p>NIEC Indonesia telah bekerja sama dengan ratusan kampus-kampus terbaik di berbagai negara. berikut ini adalah pilihan top-3 kampus yang bisa refrensi kalian</p>
                                    <button type="button" class="btn btn-danger rounded-pill">Selengkapnya <i className='bi bi-arrow-bar-right'></i></button>
                                </div>
                                <div className='col-lg-6 mt-5 clients'>
                                    <div className='row justify-content-center'>
                                        {
                                            this.state.platinum.map((univ) => (
                                                <div class="col-lg-4 col-md-4 col-6 d-flex align-items-center">
                                                    <img src={univ.logo}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="testimonials" class="testimonials">
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h5>TESTIMONIAL</h5>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-lg-12 text-center'>
                                    <h2>Apa yang Siswa dan Orang Tua Katakan <br/> Tentang Kami dan Layanan Kami</h2>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 text-center mb-5'>
                                    <div class="testimonial-item">
                                        <img src="assets/img/testimonials/Iqbal.jpg" class="testimonial-img" alt="" />
                                        <h3>M Iqbal Riswanda</h3>
                                        <h4>Melbourne Polytechnic</h4>
                                        <p>
                                            <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                                NIEC Indonesia sangat membantu saya untuk pengurusan sekolah
                                                ke luar negeri. Orang-orangnya sangat friendly, komunikatif, dan
                                                selalu memberikan alert sebelum deadline pengumpulan dokumen. Mereka juga informatif, apalagi untuk saya yang tidak tahu
                                                sama sekali mengenai Australia.
                                            <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-4 text-center mb-5'>
                                    <div class="testimonial-item">
                                        <img src="assets/img/testimonials/Regita.jpg" class="testimonial-img" alt="" />
                                        <h3>Ni Putu Wina Regita Dewi</h3>
                                        <h4>SMAN 2 Denpasar, Bali</h4>
                                        <p>
                                            <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                                Setelah gabung bareng tim NIEC Indonesia, ngurus student visa
                                                yang awalnya ribet banget, jadi super gampang! Customer service
                                                dari kakak-kakak NIEC Indonesia tuh asik, profesional, dan friendly
                                                banget! Makasih NIEC Indonesia udah nemenin aku dari awal
                                                sampai bisa studi di Kanada.
                                            <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-4 text-center mb-5'>
                                    <div class="testimonial-item">
                                        <img src="assets/img/testimonials/Widnyana.jpg" class="testimonial-img" alt="" />
                                        <h3>I Putu Adi Widnyana</h3>
                                        <h4>SMK Pariwisata Harapan, Bali</h4>
                                        <p>
                                            <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                                                Terima kasih NIEC Indonesia telah membantu saya dari pembuatan
                                                visa hingga dapat sekolah di luar negeri. Saya juga ingin mengajak
                                                teman-teman lainnya untuk belajar di luar negeri melalui NIEC
                                                Indonesia karena mereka akan membantu dari awal sampai akhir.
                                                Mereka juga akan selalu memberikan solusi dan support untuk
                                                kalian. Yakinlah, usaha tidak mengkhianati hasil.
                                            <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section style={{ backgroundImage: "url(assets/img/Prioritas-Sec-01.jpg)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <div className='row'>
                            <div className='col-lg-12' style={{ textAlign: "right", marginLeft: 'inherit', marginTop: '460px' }}>
                                <div class="text-center">
                                    <h2><a className='btn-default-2' href='http://prioritas.niecindonesia.com/#register' target='_blank'>Daftar Disini <i class="bi bi-arrow-bar-right"></i></a></h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        )
    }

}

export default (Home);