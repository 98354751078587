import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
import "../artikel/artikel.css";
import API_URL from "../../ApiUrl";
import axios from "axios";
import moment from "moment";

const ArtikelDetail = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [artikelLain, setArtikelLain] = useState([]);
  const [artikelLainLoaded, setArtikelLainLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const slug = props.match.params.slug;
    fetchArtikelData(slug);
    fetchArtikelLain();
  }, [props.match.params.slug]);

  const fetchArtikelData = (slug) => {
    axios
      .get(`${API_URL.superStudent}/api/v1/application/website/artikel/${slug}`)
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        setError("Gagal mengambil data artikel.");
        console.error("Error fetching artikel:", error);
      });
  };

  const fetchArtikelLain = () => {
    const slug = props.match.params.slug;
    axios
      .get(`${API_URL.superStudent}/api/v1/application/website/artikel`)
      .then((response) => {
        const allArtikel = response.data.data;
        const artikelDetail = allArtikel.find(
          (article) => article.slug === slug
        );
        const code_artikel_kategori = artikelDetail.code_artikel_kategori;
        const filteredArtikel = allArtikel
          .filter(
            (article) =>
              article.code_artikel_kategori === code_artikel_kategori &&
              article.slug !== slug
          )
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 5);

        setArtikelLain(filteredArtikel);
        setArtikelLainLoaded(true);
      })
      .catch((error) => {
        console.error("Data Tidak Masuk:", error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <section>
        {/* <HelmetProvider>
          <Helmet>
            <title>{data.judul}</title>
            <meta property="og:title" content={data.judul} />
            <meta
              property="og:description"
              content={data.isi.slice(0, 90) + "..."}
            />
            <meta property="og:image" content={data.image} />
            <meta property="og:url" content={location.href} />
          </Helmet>
        </HelmetProvider> */}

        <div className="container">
          <h1 className="judul">
            <strong>{data.judul}</strong>
          </h1>
          <div className="row">
            <div className="col-lg-8">
              <div className="card">
                <img src={data.image} className="cardimg" alt="..." />
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <div className="author">
                          <h4>NIEC Indonesia</h4>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="tanggal_detail">
                          <i className="bi bi-calendar4-week">
                            {moment(data.updated_at).format(" DD MMMM YYYY")}
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="text-with-image">
                    {/* <img
                      src={data.image}
                      className="cardimg float-right"
                      alt="..."
                    /> */}
                    <p
                      className="card-text-artikel"
                      dangerouslySetInnerHTML={{ __html: data.isi }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="konten-ke2">
                <h5>Artikel Yang Berkaitan</h5>
              </div>
              <div className="card-konten-2">
                {artikelLainLoaded && artikelLain.length > 0 ? (
                  <ul className="list-group list-group-flush">
                    {artikelLain.map((article, index) => (
                      <li key={index}>
                        <Link to={`/artikeldetail/${article.slug}`}>
                          <div className="card-artikel-sidebar">
                            <hr />
                            <h5 className="card-title title-sidebar">
                              <strong>{article.judul}</strong>
                            </h5>{" "}
                            <div className="row g-0">
                              <div className="col-md-5  d-flex align-items-center">
                                {article.image && (
                                  <img
                                    src={article.image}
                                    className="img-artikel-sidebar img-fluid pr-5 rounded-start cardim"
                                    alt={article.judul}
                                  />
                                )}
                              </div>
                              <div className="col-md-7">
                                <div className="card-body">
                                  <p
                                    className="card-text font-sidebar"
                                    dangerouslySetInnerHTML={{
                                      __html: article.isi.slice(0, 110) + "...",
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Tidak ada artikel yang berkaitan.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArtikelDetail;
