import React from 'react'
import { FormatRupiah } from '../services/FormatRupiah';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';

const FormPengiriman = ({kurir, ongkir, setOngkir, cartTotal, nextStep}) => {
  const formSchema = Yup.object().shape({
    kurir: Yup.string()
    .required("Pilih Kurir")
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema)});

  const handleChange = (e) => {
      let index = e.target.value
      let selectedKurir = kurir[index]
      let total = parseInt(selectedKurir?.price) + cartTotal
      setOngkir({...ongkir, 
        ongkir: selectedKurir?.price, 
        total: total,
        courier_code: selectedKurir?.courier_code,
        courier_type: selectedKurir?.type
      })
  }

  const onSubmit = (data) => {
    nextStep();
  }
  return (
    <div className="box-form">
        <h3>Pengiriman</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
              <label for="pengiriman">Pilih Pengiriman</label>
              <select {...register('kurir')} className='form-select mb-3' name="kurir" id="pengiriman" onChange={handleChange}>
                <option value="">Pilih Kurir</option>
                {kurir.map((kr, i) => {
                  return (
                    <option key={i} value={i}>{kr.courier_name} | {kr.description} (Estimasi {kr.shipment_duration_range} {kr.shipment_duration_unit}) - {FormatRupiah(kr.price)}</option>
                  )
                })}
              </select>
            </div>
            <div className="text-end">
                <button type='submit' className='btn btn-success'>Pilih Pengiriman</button>
            </div>
        </form>
    </div>
  )
}

export default FormPengiriman