import React, { useEffect, useState } from 'react'
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import Multiselect from 'multiselect-react-dropdown';
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const FormRegisMember = ({infoAlamat, nextStep, setRegistrasi}) => {
    const [consultant, setConsultant] = useState([])
    const [provinsi, setProvinsi] = useState([])
    const [kodeSekolah, setKodeSekolah] = useState([])
    const [sekolah, setSekolah] = useState([])
    let password;

    const formSchema = Yup.object().shape({
        email: Yup.string()
        .required("Email wajib diisi")
        .email("Email tidak valid"),
        code_konsultan: Yup.string()
        .required("Pilih salah satu konsultan"),
        id_provinsi: Yup.string()
        .required("Pilih provinsi sekolah"),
        password: Yup.string()
        .required("Password wajib diisi"),
        cpassword: Yup.string()
        .required("Konfirmasi Password wajib diisi")
        .oneOf([Yup.ref("password")], "Password tidak sesuai"),
    });
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setError
      } = useForm({
        mode: "onTouched",
        resolver: yupResolver(formSchema)});
    
    password = watch("password", "");

    const setPilihanSekolah = (e) => {
        let kode = e[0]?.kode_sekolah;
        setKodeSekolah(kode)
    }

    const onSubmit = (data) => {
        console.log(data.email)
        const regis = {...data,
            kode_sekolah: kodeSekolah,
            nama_lengkap: infoAlamat.nama_lengkap,
            no_wa: infoAlamat.notelp,
            id_propinsi: infoAlamat.provinsi,
            id_kabkota: infoAlamat.kabupaten,
        }
        setRegistrasi(regis);
        axios.post(API_URL.superStudent2 + '/niec-merchandise/check-member', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Authorization': `Bearer ${API_URL.keyMembership}`
            }
        }).then(res => {
            if (res.data.data === 'expired') {
                setError('email', { type: 'custom', message: res.data.message });
            } else {
                nextStep()
            }
        })
        .catch(err => console.log(err))
    }

    const handleSekolah = (e) => {
        const {value} = e.target
        axios.get(API_URL.superStudent2 + '/sekolah-indonesia/' + value)
        .then(res => setSekolah(res.data.data))
        .catch(err => console.log(err))
    }

    useEffect(()=>{
        getListConsultant();
        getDataPropinsi();
    }, [])

    const getListConsultant = () => {
        axios.get(API_URL.superStudent2 + '/master/consultant/list-active')
        .then(res => setConsultant(res.data.data))
        .catch(err => console.log(err))
    }

    const getDataPropinsi = () => {
        axios.get(API_URL.superStudent2 + '/master/provinces')
        .then(res => setProvinsi(res.data.data))
        .catch(err => console.log(err))
    }

  return (
    <div className="box-form">
        <h3>Registrasi Member</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div class="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input type="email" {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" placeholder="Email" />
                {errors.email && <div className='invalid-feedback'>{errors.email.message}</div>}
            </div>
            <div class="form-group mb-3">
                <label htmlFor="Password">Password</label>
                <input type="Password" {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="Password" placeholder="Password" />
                {errors.password && <div className='invalid-feedback'>{errors.password.message}</div>}                
            </div>
            <div class="form-group mb-3">
                <label htmlFor="confirm_password">Confirm Password</label>
                <input type="password" {...register("cpassword", { required: true})} className={`form-control ${errors.cpassword ? 'is-invalid' : ''}`} id="confirm_password" placeholder="Confirm Password" />
                {errors.cpassword && <div className='invalid-feedback'>{errors.cpassword.message}</div>}
            </div>
            <hr />
            <div className="form-group mb-3">
                <label htmlFor="konsultan">Pilih Konsultan</label>
                <select {...register("code_konsultan")} className={`form-control ${errors.code_konsultan ? 'is-invalid' : ''}`} id="konsultan">
                    <option value="">Pilih Konsultan</option>
                {
                    consultant?.map((con) => {
                        return (
                            <option key={con.id} value={con.code}>{con.name} ({con.office.kota})</option>
                        )
                    })
                }
                </select>
                {errors.code_konsultan && <div className='invalid-feedback'>{errors.code_konsultan.message}</div>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="id_provinsi">Pilih Provinsi Sekolah</label>
                <select {...register("id_provinsi")} className={`form-control ${errors.id_provinsi ? 'is-invalid' : ''}`} onChange={handleSekolah} id="id_provinsi">
                    <option value="">Pilih Provinsi Sekolah</option>
                    {
                        provinsi?.map((prov, i) => {
                        return (
                            <option key={i} value={prov.id}>{prov.province_name}</option>
                        )
                        })
                    }
                </select>
                {errors.id_provinsi && <div className='invalid-feedback'>{errors.id_provinsi.message}</div>}
            </div>
            <div className='form-group mb-3'>
                <Multiselect
                    className='form-input form-select'
                    onSelect={setPilihanSekolah}
                    onRemove={setPilihanSekolah}
                    options={sekolah}
                    selectionLimit={1}
                    emptyRecordMsg="data tidak ditemukan, kosongkan pilihan."
                    displayValue='nama_sekolah'
                    placeholder="Nama Sekolah"
                />
            </div>
        <div className="text-end">
            <button type='submit' className='btn btn-success'>Registrasi</button>
        </div>
        </form>
    </div>
  )
}

export default FormRegisMember