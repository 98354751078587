import React, { useState } from 'react'
import '../career/career.css'
import { Link } from 'react-router-dom'

const CareerView = ({data, area, list_jobdesk, filterLowongan}) => {
    const [filter, setFilter] = useState({})
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFilter({...filter, [name]: value})
    }

    const setFilterLowongan = (e) => {
        e.preventDefault();
        filterLowongan(filter);
    }
    
  return (
    <>
       <section className='career'>
        <div className="bg-header">
            <h4>NIEC CAREER</h4>
        </div>
        <div className="container content-lowongan">
            <div className="filter-card">
                <h4 className='text-center'>Filter Lowongan</h4>
                <form>
                    <div className="row">
                        <div className="col-lg-5">
                            <div class="form-group">
                                <select class="form-select" name='id_internal_area' id="job-position" onChange={handleChange}>
                                    <option value="">--Pilih Branch--</option>
                                {
                                 area?.map((a, i)=>{
                                    return (
                                        <option key={i} value={a.id_area}>{a.area}</option>
                                    )
                                 })   
                                }
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div class="form-group">
                                <select class="form-select" name='department' id="job-position" onChange={handleChange}>
                                    <option value="">--Pilih Kategori Bidang--</option>
                            {
                                list_jobdesk?.map((m, i)=>{
                                    return (
                                    <option key={i} value={m.jobdesk}>{m.jobdesk}</option>   
                                    )
                                })
                            }
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div class="form-group">
                                <button onClick={setFilterLowongan} className='btn btn-primary w-100'>Cari</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="list-lowongan">
                <div className="row">
            {
                data?.map((d,i)=>{
                    return (
                    <div key={i} className="col-lg-4">
                        <div className="card-lowongan">
                            <h4>{d.title}</h4>
                            <p><i class="bi bi-briefcase"></i> {d.department}</p>
                            <p><i class="bi bi-geo-alt"></i> {d.internal_area?.area}</p>
                            <div className="button">
                                <Link to={`${process.env.PUBLIC_URL}/career/${d.slug_title}`}>
                                <button className='btn btn-success btn-apply'>Apply <i class="bi bi-send"></i></button></Link>
                            </div>
                        </div>
                    </div>
                    )
                })
            }
                </div>
            </div>
        </div>
       </section>
    </>
  )
}

export default CareerView