import React, { Component, Fragment } from 'react';

class CountryKOR extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-kor'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>KOREA SELATAN</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                        Korea Selatan adalah negara no 4 ekonomi terbesar di ASIA. Sistem pendidikan di Korea Selatan terkenal ketat dan berkualitas. Korea Selatan adalah salah satu negara yang banyak diminati oleh kaum pelajar. Soalnya, banyaknya pusat pendidikan ternama yang menjadi incaran. Terlebih, Korea Selatan juga dikenal sebagai negara maju dengan teknologi terkini yang sering digambarin melalui visualisasi drama Korea (drakor). Korsel juga jadi kiblat fashion kaum milenial. Korea Selatan juga adalah kota no 2 dengan tingkat inovasi tertinggi di dunia by Bloomberg di thn 2020
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>KOREA SELATAN</strong></h1>
                                <h4><strong>Mengapa Korea Selatan</strong></h4>
                                <p className='text-justify'>
                                    Perkembangan dunia seni perfilman dan musik di korea selatan menjadikan negara ini mendapatkan perhatian dunia. Tentunya, perkembangan dunia pendidikan mereka menjadi salah satu pemicu dari pertumbuhan industry di korea yang memang sudah di kenal di seluruh dunia.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE UNIVERSITY</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>2,3 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>3 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>300 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>81 ribu / Jam</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Korea Selatan</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-6'>
                                <img className='img-fluid mb-3' src="../../assets/img/country/KOR/seoul.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Seoul</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img className='img-fluid mb-3' src="../../assets/img/country/KOR/busan.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Busan</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in South Korea</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/KOR/gangnam.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Gangnam</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/KOR/jeju.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Jeju Island</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/KOR/nami.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Nami Island</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/KOR/gyeongbokgung.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Gyeongbokgung Palace</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryKOR);