import React, { Component, Fragment } from 'react';

class CountryCHE extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-che'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>Switzerland</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                        Bukan menjadi rahasia lagi bahwa Swiss adalah negara dengan ranking terbaik untuk tinggal. Rata rata penduduk Swiss memiliki index OEBC yang lebih baik dari rata rata penduduk dunia dalam tingkat kebahagiaan, pekerjaan, pendapatan, work-lifebalance.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>SWISS</strong></h1>
                                <h4><strong>Mengapa Swiss</strong></h4>
                                <p className='text-justify'>
                                    Swiss sangat di kenal dengan universitas di bidang perhotalan terbaik di dunia dimana standar pelayanan di dunia perhotelan sangatlah berpusat dari swiss.
                                    Swiss adalah negara dengan inovasi tertinggi di dunia sejak 8 tahun terakhir yang di sebabkan oleh universitas terbaik, Investasi untuk penelitian dan pengembangan, infrastrukture terbaik. Swiss juga menjadi penghubung untuk cryptocurrency dan blockhain technology.
                                </p>
                                <p className='text-justify'>
                                    Kuliah di Swiss, akan memberikan pengalaman perkuliahan yang “multiculture” karena swiss sendiri memiliki 4 bahasa resmi yaitu perancis, jerman, romans dan itali selain dari ingris yang banyak di gunakan oleh penduduknya.
                                    Dengan panorama alam yang sangat indah menjadikan swiss menjadi tempat yang sangat ideal untuk study and travel
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PUBLIC UNIVERSITY</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Pemerintah</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>PRIVATE UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Swasta</h5></tr>
                                        <tr><h5>S-1 s/d S-2</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>7,6 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1,5 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1,5 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>300 ribu / jam</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of Swiss</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/lausanne.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Lcheanne</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/Zurich.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Zurich</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/geneva.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Geneva</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/bern.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Bern</strong></h3>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in Switzerland</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/rhinefall.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Rhine Falls</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/lake.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Lake Zurich</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/CHE/Jungfraujoch.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Jungfraujoch</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryCHE);