import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

class CampusTafe extends Component {

    render() {
        
        return (
            <div>
                <section className='bg-abu'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <img className='img-fluid rounded' src="../../../assets/img/slide/tafe/Header.jpg"></img>
                        </div>
                    </div>
                </section>
                <div className='bg-abu mb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-9 text-start'>
                                <h4 className='mb-3'><strong>Western Australia</strong></h4>
                                <h1 className='mb-3'><strong>TAFE International Western Australia</strong></h1>
                                <h5 className='mb-3'>TAFE Campus, Building B, Level 2/140 Royal St, East Perth WA 6004, Australia</h5>
                            </div>
                            <div className='col-lg-3 text-end'>
                                <img className='img-fluid' src="../../assets/img/clients/campus-02.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <main id="main">
                    <div class="container mb-5">
                        <div className='row'>
                            <div className='col-lg-6 text-start'>
                                <p className='text-justify'>
                                    TAFE WA adalah penyelenggara pendidikan kejuruan terbesar di Australia Barat dan salah satu penyelenggara pendidikan yang berfokus pada karir terkemuka di Australia.Ada banyak keunggulan yang dimiliki oleh TAFE International Western yang membuat mahasiswa internasional menjadikan kampus ini sebagai tujuan study, slah satunya adalah persyaratannya sangat fleksibel sehingga memberikan kesempatan bagi siapa saja yan ingin belajar. Di TIWA, ada banyak course atau lebih dari 250 tempat kursus yang bisa anda jadikan tempat belajar.Selain itu, banyak mahasiswa internasional memilih belajar di TAFE karena mutu pendidikannya yang terbaik. Di TAFE, mahasiswa dapat memperoleh kemampuan yang praktis dan juga professional serta punya pengalaman nyata yang akan membawa anda ke karir yang anda mau.
                                </p>
                            </div>
                            <div className='col-lg-6 text-end'>
                                <div className='container'>
                                    <div className='row card-promo-campus-top'>
                                        <div className='col-lg-6'>
                                            <h5>TAFE International Western Australia</h5>
                                            <p>*Hanya Beberapa Jurusan</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h3>Promo Type</h3>
                                        </div>
                                    </div>
                                    <div className='row text-start card-promo-campus-middle'>
                                        <div className='col-lg-12'>
                                            <h4 className='mb-3'><strong></strong></h4>
                                            <h5></h5>
                                        </div>
                                    </div>
                                    <div className='row text-start card-promo-campus-bottom'>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-3 text-center'>
                                                    <h1><i class="bi bi-calendar-check"></i></h1>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <p>Berlaku Sampai</p>
                                                    <p>31 September 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-3 text-center'>
                                                    <h1><i class="bi bi-cash-coin"></i></h1>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <p>Value</p>
                                                    <i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container mb-5">
                        <Tabs defaultActiveKey="keunggulan" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="keunggulan" title="Keunggulan">
                                <p className='text-justify'>
                                    Tafe western australia memberikan kemudahan akses untuk melanjutkan ke universitas TOP di Western Australia untuk Bachelor Degree / S1.
                                </p>
                                <br/>
                                <p>
                                    Tafe western Australia juga berlokasi di regional area Australia yang membersikan keuntungan seperti: biaya kuliah yang murah, durasi extra untuk tinggal di australia melalui graduate visa, peluang kerja yang terbuka lebar, dan gaji rata rata yang lebih tinggi di bandingkan provinsi lain di Australia.
                                </p>
                                <br/>
                                <p>
                                    TAFE WA adalah penyelenggara pendidikan kejuruan terbesar di Australia Barat dan salah satu penyelenggara pendidikan yang berfokus pada karir terkemuka di Australia.Ada banyak keunggulan yang dimiliki oleh TAFE International Western yang membuat mahasiswa internasional menjadikan kampus ini sebagai tujuan study, slah satunya adalah persyaratannya sangat fleksibel sehingga memberikan kesempatan bagi siapa saja yan ingin belajar. Di TIWA, ada banyak course atau lebih dari 250 tempat kursus yang bisa anda jadikan tempat belajar.Selain itu, banyak mahasiswa internasional memilih belajar di TAFE karena mutu pendidikannya yang terbaik. Di TAFE, mahasiswa dapat memperoleh kemampuan yang praktis dan juga professional serta punya pengalaman nyata yang akan membawa anda ke karir yang anda mau.
                                </p>
                            </Tab>
                            <Tab eventKey="biaya" title="Biaya">
                                <h5>$ 8,000/ semester</h5>
                            </Tab>
                        </Tabs>
                    </div>
                    <div class="container mb-5 text-center">
                        <h1 className='text-center mb-5'><strong>Beberapa Jurusan yang masuk Top Dunia</strong></h1>
                        <h4 className='text-center mb-5'>Terdapat ratusan jurusan untuk program S1, S2, S3 dengan program unggulan</h4>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/tafe/IT.jpg"/>
                                <h3>Digital Media and Information Technology</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/tafe/Health.jpg"/>
                                <h3>Health</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/tafe/Hospitality.jpg"/>
                                <h3>Hospitality, cookery and tourism</h3>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/tafe/Engineering.jpg"/>
                                <h3>Engineering</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/tafe/Creative.jpg"/>
                                <h3>Creative Industries</h3>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );

    }
}

export default (CampusTafe);