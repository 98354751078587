import React, { useEffect, useState } from 'react'
import axios from 'axios';
import API_URL from '../../../ApiUrl';
import { useForm } from "react-hook-form"
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const FormAlamat = ({nextStep, setInfoAlamat, infoAlamat, setKurir, items}) => {
    const [provinsi, setProvinsi] = useState([]);
    const [kabupaten, setKabupaten] = useState([]);
    const formSchema = Yup.object().shape({
        nama_lengkap: Yup.string()
        .required("Nama wajib diisi"),
        email: Yup.string()
        .required("Email wajib diisi")
        .email('Email Tidak Valid'),
        alamat: Yup.string()
        .required("Alamat wajib diisi"),
        provinsi: Yup.string()
        .required("Pilih provinsi"),
        notelp: Yup.string()
        .required("Nomor Telepon wajib diisi"),
        kabupaten: Yup.string()
        .required("Pilih Kabupaten"),
        postal_code: Yup.string()
        .required("Kode Pos wajib diisi")
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
      } = useForm({
        mode: "onTouched",
        resolver: yupResolver(formSchema)});

    const onSubmit = (data) => {
        axios.get(`https://api.biteship.com/v1/maps/areas?countries=ID&input=${data.postal_code}&type=single`, {
            headers: {
                'Authorization': `Bearer ${API_URL.biteship_token}`
            }
        })
        .then(res => {
            let area = res.data.areas;
            if (area.length < 1) {
                setError('postal_code', { type: 'custom', message: 'Kode Pos tidak terdaftar/tidak valid' });
            } else {
                setInfoAlamat(data)
                getKurirOngkir(data)
            }
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        getDataPropinsi();
    }, [])

    const getKurirOngkir = (data) => {
        const kurirs = { 
            "origin_postal_code": 80111,
            "destination_postal_code": parseInt(data.postal_code),
            "couriers":"jne,jnt,grab,gojek",
            "items": items
        }

        // let rates = {
        //     "success": true,
        //     "object": "courier_pricing",
        //     "message": "Success to retrieve courier pricing",
        //     "code": 20001003,
        //     "origin": {
        //         "location_id": null,
        //         "latitude": null,
        //         "longitude": null,
        //         "postal_code": 80361,
        //         "country_name": "Indonesia",
        //         "country_code": "ID",
        //         "administrative_division_level_1_name": "Bali",
        //         "administrative_division_level_1_type": "province",
        //         "administrative_division_level_2_name": "Badung",
        //         "administrative_division_level_2_type": "city",
        //         "administrative_division_level_3_name": "Kuta",
        //         "administrative_division_level_3_type": "district",
        //         "administrative_division_level_4_name": "Kuta",
        //         "administrative_division_level_4_type": "subdistrict",
        //         "address": null
        //     },
        //     "destination": {
        //         "location_id": null,
        //         "latitude": null,
        //         "longitude": null,
        //         "postal_code": 80361,
        //         "country_name": "Indonesia",
        //         "country_code": "ID",
        //         "administrative_division_level_1_name": "Bali",
        //         "administrative_division_level_1_type": "province",
        //         "administrative_division_level_2_name": "Badung",
        //         "administrative_division_level_2_type": "city",
        //         "administrative_division_level_3_name": "Kuta Selatan",
        //         "administrative_division_level_3_type": "district",
        //         "administrative_division_level_4_name": "Benoa",
        //         "administrative_division_level_4_type": "subdistrict",
        //         "address": null
        //     },
        //     "pricing": [
        //         {
        //             "available_collection_method": [
        //                 "pickup"
        //             ],
        //             "available_for_cash_on_delivery": true,
        //             "available_for_proof_of_delivery": false,
        //             "available_for_instant_waybill_id": true,
        //             "available_for_insurance": true,
        //             "company": "jne",
        //             "courier_name": "JNE",
        //             "courier_code": "jne",
        //             "courier_service_name": "Reguler",
        //             "courier_service_code": "reg",
        //             "description": "Layanan reguler",
        //             "duration": "1 - 2 days",
        //             "shipment_duration_range": "1 - 2",
        //             "shipment_duration_unit": "days",
        //             "service_type": "standard",
        //             "shipping_type": "parcel",
        //             "price": 27000,
        //             "type": "reg"
        //         },
        //         {
        //             "available_collection_method": [
        //                 "pickup"
        //             ],
        //             "available_for_cash_on_delivery": false,
        //             "available_for_proof_of_delivery": false,
        //             "available_for_instant_waybill_id": true,
        //             "available_for_insurance": true,
        //             "company": "jne",
        //             "courier_name": "JNE",
        //             "courier_code": "jne",
        //             "courier_service_name": "Yakin Esok Sampai (YES)",
        //             "courier_service_code": "yes",
        //             "description": "Yakin esok sampai",
        //             "duration": "1 - 1 days",
        //             "shipment_duration_range": "1 - 1",
        //             "shipment_duration_unit": "days",
        //             "service_type": "overnight",
        //             "shipping_type": "parcel",
        //             "price": 36000,
        //             "type": "yes"
        //         },
        //         {
        //             "available_collection_method": [
        //                 "pickup"
        //             ],
        //             "available_for_cash_on_delivery": false,
        //             "available_for_proof_of_delivery": false,
        //             "available_for_instant_waybill_id": true,
        //             "available_for_insurance": true,
        //             "company": "jnt",
        //             "courier_name": "J&T",
        //             "courier_code": "jnt",
        //             "courier_service_name": "EZ",
        //             "courier_service_code": "ez",
        //             "description": "Layanan reguler",
        //             "duration": "2 - 3 days",
        //             "shipment_duration_range": "2 - 3",
        //             "shipment_duration_unit": "days",
        //             "price": 27000,
        //             "service_type": "standard",
        //             "shipping_type": "parcel",
        //             "type": "ez"
        //         }
        //     ]
        // }

        axios({
            method: 'POST',
            url: 'https://api.biteship.com/v1/rates/couriers',
            data: kurirs,
            headers: {
                Authorization: `Bearer ${API_URL.biteship_token}`
            }
        })
        .then(res => {
            console.log(res)
            setKurir(res.data.pricing)
            nextStep()
        })
        .catch(err => console.log(err))
    }

    const setKabKota = (e) => {
        const {name, value} = e.target
        setInfoAlamat({...infoAlamat, [name]: value})
    }
    
    const loadKabKota = (e) => {
        const {name, value} = e.target
        setInfoAlamat({...infoAlamat, [name]: value})

        axios.get(API_URL.superStudent2 + '/master/kabupaten/' + value)
        .then(res => setKabupaten(res.data.data))
        .catch(err => console.log(err))
      }
    
    const getDataPropinsi = () => {
        axios.get(API_URL.superStudent2 + '/master/provinces')
        .then(res => setProvinsi(res.data.data))
        .catch(err => console.log(err))
    }
  return (
    <div className="box-form">
        <h3>Informasi Pengiriman</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div class="form-group mb-3">
            <label htmlFor="nama_lengkap">Nama Lengkap</label>
            <input type="text" {...register("nama_lengkap")} className={`form-control ${errors.nama_lengkap ? 'is-invalid' : ''}`} id="nama_lengkap" placeholder="Nama Lengkap"/>
            {errors.nama_lengkap && <div class="invalid-feedback">{errors.nama_lengkap.message}</div>}
            </div>
            <div class="form-group mb-3">
            <label htmlFor="email">Email</label>
            <input type="email" {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="email" placeholder="Nama Lengkap"/>
            {errors.email && <div class="invalid-feedback">{errors.email.message}</div>}
            </div>
            <div class="form-group mb-3">
            <label htmlFor="notelp">Nomor Telepon</label>
            <input type="number" {...register("notelp")} className={`form-control ${errors.notelp ? 'is-invalid' : ''}`}  id="notelp" placeholder="Nomor Telepon" />
            {errors.notelp && <div class="invalid-feedback">{errors.notelp.message}</div>}
            </div>
            <div class="form-group mb-3">
            <label htmlFor="alamat">Alamat</label>
            <input type="text" {...register("alamat")} className={`form-control ${errors.alamat ? 'is-invalid' : ''}`}  id="alamat" placeholder="Alamat" />
            {errors.alamat && <div class="invalid-feedback">{errors.alamat.message}</div>}
            </div>
            <div className="form-group mb-3">
                <select className={`form-control ${errors.provinsi ? 'is-invalid' : ''}`} {...register("provinsi")} id="pengiriman" onChange={loadKabKota}>
                    <option value="">Pilih Provinsi</option>
                {
                    provinsi?.map((prov, i) => {
                    return (
                        <option key={i} value={prov.id}>{prov.province_name}</option>
                    )
                    })
                }
                </select> 
                {errors.provinsi && <div class="invalid-feedback">{errors.provinsi.message}</div>}
            </div>
            <div className="form-group mb-3">
                <select className={`form-control ${errors.kabupaten ? 'is-invalid' : ''}`} {...register("kabupaten")} defaultValue={''} id="pengiriman" onChange={setKabKota}>
                    <option value="">Pilih Kabupaten/Kota</option>
                {
                    kabupaten?.map((kab) => {
                    return (
                        <option value={kab.id}>{kab.kabupaten_name}</option>
                    )
                    })
                }
                </select>
                {errors.kabupaten && <div class="invalid-feedback">{errors.kabupaten.message}</div>}
            </div>
            <div class="form-group mb-3 col-4">
                <label htmlFor="kode_pos">Kode Pos</label>
                <input type="number" className={`form-control ${errors.postal_code ? 'is-invalid' : ''}`}  {...register("postal_code")} id="kode_pos" placeholder="Kode Pos"/>                
                {errors.postal_code && <div class="invalid-feedback">{errors.postal_code.message}</div>}
            </div>
            <div className="text-end">
                <button type='submit' className='btn btn-success'>Selanjutnya</button>
            </div>
        </form>
    </div>
  )
}

export default FormAlamat