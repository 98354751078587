import React, { Fragment } from "react";
import { CartProvider } from "react-use-cart";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./components";
import Home from "./pages/home";
import About from "./pages/about";
import Artikel from "./pages/artikel";
import ArtikelDetail from "./pages/artikel/ArtikelDetail";

import campus_melbourne from "./pages/campus/campus_melbourne";
import campus_ecu from "./pages/campus/campus_ecu";
import campus_tafe from "./pages/campus/campus_tafe";

import country_aus from "./pages/country/aus";
import country_can from "./pages/country/can";
import country_jpn from "./pages/country/jpn";
import country_kor from "./pages/country/kor";
import country_nzl from "./pages/country/nzl";
import country_pol from "./pages/country/pol";
import country_tur from "./pages/country/tur";
import country_usa from "./pages/country/usa";
import country_eng from "./pages/country/eng";
import country_che from "./pages/country/che";
import country_ger from "./pages/country/ger";
import country_hun from "./pages/country/hun";

import scholarship from "./pages/scholarship";
import studytour from "./pages/studytour";
import contacus from "./pages/contacus";

import singaraja from "./pages/singaraja";
import Career from "./pages/career";
import DetailLowongan from "./pages/career/DetailLowongan";
import Merchandise from "./pages/merchandise";
import Checkout from "./pages/merchandise/Checkout";
import Tracking from "./pages/merchandise/Tracking";
import MerchandiseDetail from "./pages/merchandise/MerchandiseDetail";

const RoutersApp = () => {
  return (
    <CartProvider>
      <BrowserRouter basename={`/`}>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL}/singaraja`}
            exact
            component={singaraja}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/merchandise/tracking/:id`}
            exact
            component={Tracking}
          />

          <Fragment>
            <App>
              <Route path="/" exact component={Home} />

              <Route
                path={`${process.env.PUBLIC_URL}/about`}
                exact
                component={About}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/artikel`}
                exact
                component={Artikel}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/artikeldetail/:slug`}
                exact
                component={ArtikelDetail}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/campus/melbourne`}
                exact
                component={campus_melbourne}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/campus/ecu`}
                exact
                component={campus_ecu}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/campus/tafe`}
                exact
                component={campus_tafe}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/country/aus`}
                exact
                component={country_aus}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/can`}
                exact
                component={country_can}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/jpn`}
                exact
                component={country_jpn}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/kor`}
                exact
                component={country_kor}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/nzl`}
                exact
                component={country_nzl}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/pol`}
                exact
                component={country_pol}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/tur`}
                exact
                component={country_tur}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/usa`}
                exact
                component={country_usa}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/eng`}
                exact
                component={country_eng}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/che`}
                exact
                component={country_che}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/ger`}
                exact
                component={country_ger}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/country/hun`}
                exact
                component={country_hun}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/scholarship`}
                exact
                component={scholarship}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/study_tour`}
                exact
                component={studytour}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/career`}
                exact
                component={Career}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/career/:slug`}
                exact
                component={DetailLowongan}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/merchandise`}
                exact
                component={Merchandise}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/merchandise/checkout`}
                exact
                component={Checkout}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/MerchandiseDetail/:code_product`}
                exact
                component={MerchandiseDetail}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/contact_us`}
                exact
                component={contacus}
              />
            </App>
          </Fragment>
        </Switch>
      </BrowserRouter>
    </CartProvider>
  );
};

export default RoutersApp;
