import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCart } from "react-use-cart";
import Cart from "./Cart";
import { FormatRupiah } from "./services/FormatRupiah";

const MerchandiseHome = ({ produk }) => {
  const location = useLocation();
  const { addItem, totalUniqueItems, inCart, items, emptyCart } = useCart();
  const [showCart, setShowCart] = useState(false);
  const empty_cart = location.state?.empty_cart;

  let memberExist = false;
  for (let i = 0; i < items.length; i++) {
    if (items[i].is_with_member == "1") {
      memberExist = true;
      break;
    }
  }

  const toggle = () => {
    setShowCart(!showCart);
  };
  const handleAddToCart = (prod) => {
    console.log("Menambahkan produk ke keranjang:", prod);
    addItem(prod);
  };

  return (
    <>
      <section className="merchandise">
        <div className="container content-merchandise">
          <div className="nav-merch">
            {!showCart ? (
              <button onClick={toggle} className="btn btn-cart">
                <div class="cart">
                  <span class="count">{totalUniqueItems}</span>
                  <i class="bi bi-bag-fill bagg"></i>
                </div>
                <p>Keranjang</p>
              </button>
            ) : (
              <button onClick={toggle} className="btn btn-merc">
                <div class="cart">
                  <i class="bi bi-shop-window bagg"></i>
                </div>
                <p>Merchandise</p>
              </button>
            )}
          </div>
          {!showCart && (
            <div className="container">
              <div className="list-merchandise">
                <div className="row">
                  {produk?.map((prod, i) => {
                    const added = inCart(prod.id);
                    return (
                      <div className="col-lg-4">
                        <div
                          key={prod.id_product}
                          className="card shadow mb-5 mx-auto"
                          style={{ maxwidth: "18rem" }}
                        >
                          <a href={`/MerchandiseDetail/${prod.code_product}`}>
                            <img
                              className="card-img-top"
                              src={prod?.file_pic}
                              alt="card-img"
                            />

                            <div className="card-body">
                              <h5 className="card-title">
                                {prod?.name_product}
                              </h5>

                              <h5 className="card-title">
                                {FormatRupiah(prod?.price)}
                              </h5>
                              {/* <p className="card-text">{prod?.description}</p> */}
                            </div>
                          </a>
                          <button
                            style={{
                              marginBottom: "15px",
                              marginRight: "10px",
                            }}
                            className={`btn btn-primary ${
                              added
                                ? memberExist && prod?.is_with_member === 1
                                  ? "disabled"
                                  : !memberExist && prod?.is_with_member === 0
                                  ? "disabled"
                                  : "disabled"
                                : memberExist && prod?.is_with_member === 1
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => handleAddToCart(prod)}
                          >
                            Add to Cart
                          </button>
                          {/* <br /> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {showCart && <Cart emptyCart={emptyCart} />}
        </div>
      </section>
    </>
  );
};
export default MerchandiseHome;
