import React, { Component, Fragment } from 'react';

class CountryUSA extends Component {

    render() {
        
        return (
            <div>
                <section className='banner-lg-usa'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-lg-6' style={{marginTop: '300px'}}>
                                <h1><strong>USA</strong></h1>
                                <h4><strong>Guide to Living and Study</strong></h4>
                                <p>
                                    <strong>
                                        USA adalah negara dengan jumlah International Student terbanyak di Dunia. Qualitas pendidikan yang tebaik, lingkungan yang “multiculture” menjadikan USA menjadi pilihan bagi International student.
                                    </strong>
                                </p>
                            </div>
                            <div className='col-lg-6'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section className='banner-lg-aus-01'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <img className='img-fluid' src="../../../assets/img/country/AUS/aus-01.png"></img>
                            </div>
                            <div className='col-lg-7'>
                                <h1><strong>USA</strong></h1>
                                <h4><strong>Mengapa USA</strong></h4>
                                <p className='text-justify'>
                                    Study di USA memiliki Campus campus terbaik di dunia dengan jurusan jurusan yang terbaik di bidang teknologi dan bisnis.
                                    Lulusan dari Universitas di USA sudah sangat di kenal di dunia sehingga memberikan peluang kerja yang sangat luas untuk bisa berkaris di perusahaan mulinasional di seluruh dunia.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container border-aus-01'>
                        <h1 className='mb-5'><strong>Tipe Campus</strong></h1>
                        <div className='row text-center'>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>COMMUNITY COLLEGE</strong></h2>
                                <table className='table-border table-center'>
                                    <td>
                                        <tr><h5>Swasta / Pemerintah</h5></tr>
                                        <tr><h5>Diploma (2 tahun)</h5></tr>
                                    </td>
                                </table>
                            </div>
                            <div className='col-lg-6'>
                                <h2><strong><i class="bi bi-mortarboard-fill"></i></strong></h2>
                                <h2 className='mb-5'><strong>UNIVERSITY</strong></h2>
                                <table className='table-center'>
                                    <td>
                                        <tr><h5>Swasta / Pemerintah</h5></tr>
                                        <tr><h5>S-1 s/d S-3</h5></tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div className='row justify-content-center mb-5'>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>5,8 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong>Tempat Tinggal</strong></h3>  
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>1,5 juta / Bulan</strong></h2>
                                </div>
                                <h3><strong></strong></h3>
                                <h3><strong>Makan</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi'>
                                    <h4 className='mt-5'><strong>Estimasi Dari</strong></h4>
                                    <h2><strong>600 ribu / Bulan</strong></h2>
                                </div>
                                <h3><strong>Transport</strong></h3>
                            </div>
                            <div className='col-xl-3 col-sm-6 col-xs-12 text-center mb-5'>
                                <div className='akomodasi-ger'>
                                    <h4 className='mt-5'><strong>Mulai Dari</strong></h4>
                                    <h2><strong>-</strong></h2>
                                </div>
                                <h3><strong>Gaji Part-time</strong></h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container'>
                        <h2 className='mb-5'><strong>City Of USA</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/new_york.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>New York</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/boston.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Boston</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/florida.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Florida</strong></h3>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/California.jpeg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>California</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/san-fransico.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>San Fransico</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/chichago.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Chicago</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='bg-default'>
                    <div className='container bg-default'>
                        <h2 className='mb-5'><strong>Things to do in USA</strong></h2>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/grandcanyon.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>The Grand Canyon Arizona</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/yellowstone.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Yellowstone National Park</strong></h3>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid img-jurusan mb-3' src="../../assets/img/country/USA/liberty.jpg"/>
                                <div className="card-jurusan container">
                                    <h3><strong>Statue of Liberty</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}

export default (CountryUSA);