import React, { Component } from "react";
import { Helmet } from "react-helmet";

import "../artikel/artikel.css";
import axios from "axios";
import API_URL from "../../ApiUrl";
import ArtikelItem from "./ArtikelItem";
import ArtikelList from "./ArtikelList";

class Artikel extends Component {
  truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  constructor(props) {
    super(props);
    this.state = {
      data_artikel: [],
      kategori_artikel: [],
    };
  }
  componentDidMount() {
    this.getArtikel();
    this.getKategoriArtikel();
  }

  getArtikel() {
    axios
      .get(API_URL.superStudent + "/api/v1/application/website/artikel", {
        headers: {
          Accept: "application/json",
        },
      })
      // .get(API_URL.superStudent2 + "/api/v1/application/webiste/artikel", {
      //   headers: {
      //     Accept: "application/json",
      //   },
      // })
      .then((response) => response.data.data)
      .then((result) => {
        this.setState({ data_artikel: result });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getKategoriArtikel() {
    axios
      .get(
        API_URL.superStudent + "/api/v1/application/website/artikel-kategori",
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      // .get(
      //   API_URL.superStudent2 + "/api/v1/application/website/artikel-kategori",
      //   {
      //     headers: {
      //       Accept: "application/json",
      //     },
      //   }
      // )
      .then((response) => response.data.data)
      .then((result) => {
        this.setState({ kategori_artikel: result });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  detailDesc(e) {
    this.setState({
      modal: true,
      description: e,
    });
  }
  render() {
    const { data_artikel, kategori_artikel } = this.state;
    return (
      <div>
        <section>
          <div className="container">
            <div className="list-artikel">
              <div className="row">
                <ArtikelItem
                  data={data_artikel}
                  kategori_artikel={kategori_artikel}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Artikel;
