//dev
// const url = "http://103.41.205.87:2222";
// const master = "http://103.41.205.87:1936";
// const membership = "http://103.41.205.45:1935";
// const masterstudents = "http://103.41.205.87:1941";
// const globalambassador = "http://103.41.205.87:1945";
// const ecu = "http://103.41.205.87:1946";
// const membershipKey = 'FYzev0v4c2wLHoewHjLQHps1WxnhLgVB';
// const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
// const niecsisKey = '6zhKURQZ2c8J5q1jfUEPzgiQeYVCvNIN';
// const masterstudentsKey = 'fxADrYTrLmQKghvmWif299leWza34wBJ';
// const globalambassadorKey = 'yl3Z9paufl0eEjpczRoj9V0uI9deEr7J';
// const ecuKey = 'lsspZ63nEFomZA4NMaEAWAhAoWraoiFM';
// const tiwa = "http://103.41.205.87:1932";
// const tiwaKey = 'JjHw7bGiUlwhSXNF37JhP7U5AwikZimY';
// const melPoly = "http://103.41.205.87:1933";
// const melPolyKey = '1daWPrZjRmHzjBBQfT6w8GCocvhi7cQp';
// const inspiringstudent = 'http://103.41.205.87:1948';
// const inspiringstudentKey = 'vTM8kLL1cN6bXjwPWRACSpzJoZRCOphy';
// const chisholm ='http://103.41.205.87:1952';
// const chisholmKey = 'KvrK5UcE3rR4jhU90VQ3Q268LCXWB4J8';
// const waRegis = 'http://103.41.205.87:1951';
// const waRegisKey = 'KIEtvrCBf1jB8cdbwTrZNJPheue8Ta80';
// const englishCourse = 'http://103.41.205.87:1949';
// const englishCourseKey = 'WUhtZP40YhPa39Fg5zdOubgE4Zjb8bJN';
// const superStudent = 'http://103.41.205.87:80';
// const superStudent2 = 'http://103.41.205.87:80/api/v1/application';
// const eventUrl = 'http://103.41.205.87:80/event/'
// const eventToken = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'
// const baseSuperStudent = 'http://103.41.205.87:80/storage';
// const ambassadorKey = 'Y3G4gG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV';
// const biteship_token = 'biteship_test.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTklFQyBNZXJjaGFuZGlzZSBLZXkiLCJ1c2VySWQiOiI2NTlkMGQ0OTVlNDFlZDM2Yzc4NzQyNTYiLCJpYXQiOjE3MDUwMzE0Mzh9.ipyh-88NZ5BKdN-lue0DOOOtSrSegjjXVVQa2Z-edd0';
// const keyMembership = 'OiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY1MDA5NDQyMCwia';

// // prod
const url = "http://103.41.205.45:1928";
const master = "http://103.41.205.45:1936";
const membership = "http://103.41.205.45:1935";
const masterstudents = "http://103.41.205.45:1941";
const globalambassador = "http://103.41.205.45:1945";
const ecu = "http://103.41.205.45:1946";
const membershipKey = 'FYzev0v4c2wLHoewHjLQHps1WxnhLgVB';
const masterKey = 'koibg3kyOxX73kKTNwCsbNXnocy6nMPQ';
const niecsisKey = '6zhKURQZ2c8J5q1jfUEPzgiQeYVCvNIN';
const masterstudentsKey = 'fxADrYTrLmQKghvmWif299leWza34wBJ';
const globalambassadorKey = 'yl3Z9paufl0eEjpczRoj9V0uI9deEr7J';
const ecuKey = 'lsspZ63nEFomZA4NMaEAWAhAoWraoiFM';
const tiwa = "http://103.41.205.45:1932";
const tiwaKey = 'JjHw7bGiUlwhSXNF37JhP7U5AwikZimY';
const melPoly = "http://103.41.205.45:1933";
const melPolyKey = '1daWPrZj RmHzjBBQfT6w8GCocvhi7cQp';
const inspiringstudent = 'http://103.41.205.45:1948';
const inspiringstudentKey = 'vTM8kLL1cN6bXjwPWRACSpzJoZRCOphy';
const chisholm ='http://103.41.205.45:1952';
const chisholmKey = 'KvrK5UcE3rR4jhU90VQ3Q268LCXWB4J8';
const waRegis = 'http://103.41.205.45:1951';
const waRegisKey = 'KIEtvrCBf1jB8cdbwTrZNJPheue8Ta80';
const englishCourse = 'http://103.41.205.45:1949';
const englishCourseKey = 'WUhtZP40YhPa39Fg5zdOubgE4Zjb8bJN';
const superStudent = 'https://superstudent.niecindonesia.com';
const superStudent2 = 'https://superstudent.niecindonesia.com/api/v1/application';
const baseSuperStudent = 'https://superstudent.niecindonesia.com/storage';
const eventUrl = 'https://superstudent.niecindonesia.com/event/';
const eventToken = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ';
const ambassadorKey = 'Y3G4gG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV';
const biteship_token = 'biteship_live.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiUFJPRF9BUElfTUVSQ0hBTkRJU0UiLCJ1c2VySWQiOiI2NTlkMGQ0OTVlNDFlZDM2Yzc4NzQyNTYiLCJpYXQiOjE3MDg5MjkyMTF9.gbsV6AfkmfYBzXz-jJyNFnn6Qdcprk0pAPQxTfJuITc'


const API_URL = {
    url,
    master,
    membership,
    globalambassador,
    ecu,
    membershipKey,
    masterKey,
    niecsisKey,
    masterstudents,
    masterstudentsKey,
    globalambassadorKey,
    ecuKey,
    tiwa,
    tiwaKey,
    melPoly,
    melPolyKey,
    inspiringstudent,
    inspiringstudentKey,
    englishCourse,
    englishCourseKey,
    chisholm,
    chisholmKey,
    waRegis,
    waRegisKey,
    superStudent,
    superStudent2,
    baseSuperStudent,
    eventUrl,
    eventToken,
    ambassadorKey,
    biteship_token
}

export default API_URL;
