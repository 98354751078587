import React, { Component } from "react";
import MerchandiseHome from "./MerchandiseHome";
import MerchandiseDetail from "./MerchandiseDetail";
import "../merchandise/merchandise.css";
import axios from "axios";
import API_URL from "../../ApiUrl";
import { CartProvider } from "react-use-cart";

class Merchandise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produk: [],
      selectedProductDetail: null,
    };
  }

  componentDidMount() {
    this.getDataProduct();
  }

  getDataProduct() {
    axios
      .get(`${API_URL.superStudent2}/niec-merchandise/produk`)
      .then((res) => this.setState({ produk: res.data }))
      .catch((err) => console.log(err));
  }

  getDataProductDetail = (code_product) => {
    axios
      .get(
        `${API_URL.superStudent2}/api/v1/application/niec-merchandise/produk-detail/${code_product}`
      )
      .then((res) => this.setState({ selectedProductDetail: res.data }))
      .catch((err) => console.error(err));
  };

  handleProductClick = (code_product) => {
    this.getDataProductDetail(code_product);
  };

  render() {
    const { produk, selectedProductDetail } = this.state;

    return (
      <>
        {/* <CartProvider> */}
        {selectedProductDetail ? (
          <MerchandiseDetail produk_detail={selectedProductDetail} />
        ) : (
          <MerchandiseHome
            produk={produk}
            onProductClick={this.handleProductClick}
          />
        )}
        {/* </CartProvider> */}
      </>
    );
  }
}

export default Merchandise;
