import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

class CampusMelbourne extends Component {

    render() {
        
        return (
            <div>
                <section className='bg-abu'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <img className='img-fluid rounded float-right' src="../../../assets/img/slide/melbourne/Header.jpg"></img>
                        </div>
                    </div>
                </section>
                <div className='bg-abu mb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-9 text-start'>
                                <h4 className='mb-3'><strong>Australia / Melbourne</strong></h4>
                                <h1 className='mb-3'><strong>Melbourne Polytechnic</strong></h1>
                                <h5 className='mb-3'>77 St Georges Rd, Preston VIC 3072, Australia</h5>
                            </div>
                            <div className='col-lg-3 text-end'>
                                <img className='img-fluid' src="../../assets/img/clients/campus-01.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <main id="main">
                    <div class="container mb-5">
                        <div className='row'>
                            <div className='col-lg-6 text-start'>
                                <p className='text-justify'>
                                    Melbourne Polytechnic menawarkan lebih dari 100 program yang tersebar di beberapa kampus di Melbourne, Australia. Kampus ini merupakan salah satu dari penyedia pendidikan tinggi terbesar di Australia, dengan mahasiswa yang berasal dari lebih dari 70 negara. Melbourne Polytechnic juga meraih Victorian Government's Premier's Award for Excellence in International Education.Melbourne Polytechnic menerapkan program Student Life at MelbPoly (SLAM), yang mengadakan beragam aktifitas, acara dan perjalanan bagi mahasiswa agar dapat ikut terlibat sepanjang tahun.
                                </p>
                            </div>
                            <div className='col-lg-6 text-end'>
                                <div className='container'>
                                    <div className='row card-promo-campus-top'>
                                        <div className='col-lg-6'>
                                            <h3>Melbourne Polytechnic</h3>
                                            <p>*Hanya Beberapa Jurusan</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h2>Promo Type</h2>
                                        </div>
                                    </div>
                                    <div className='row text-start card-promo-campus-middle'>
                                        <div className='col-lg-12'>
                                            <h4 className='mb-3'><strong>Scholarship</strong></h4>
                                            <h5>Pathway to victoria Scholarship Program : $2,500</h5>
                                            <h5>International Higher Education Scholarship 10 % - 20%</h5>
                                        </div>
                                    </div>
                                    <div className='row text-start card-promo-campus-bottom'>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-3 text-center'>
                                                    <h1><i class="bi bi-calendar-check"></i></h1>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <p>Berlaku Sampai</p>
                                                    <p>31 September 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className='col-lg-3 text-center'>
                                                    <h1><i class="bi bi-cash-coin"></i></h1>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <p>Value</p>
                                                    <i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i><i class="bi bi-currency-dollar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container mb-5">
                        <Tabs defaultActiveKey="keunggulan" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="keunggulan" title="Keunggulan">
                                <p className='text-justify'>
                                    Melbourne Polytechnic berada di kota Melbourne yang merupakan salah satu kota terbaik untuk siswa dari luar negeri.
                                    melbourne polytecnic juga memberikan pembelajaran yang mengutamakan pendidikan teori dan praktek yang seimbang sehingga semua siswa nya di pastikan akan
                                    mendapatkan skill yang mampu bersaing di dunia industri.
                                </p>
                            </Tab>
                            <Tab eventKey="biaya" title="Biaya">
                                <h5>$ 8,000/ semester</h5>
                            </Tab>
                        </Tabs>
                    </div>
                    <div class="container mb-5 text-center">
                        <h1 className='text-center mb-5'><strong>Beberapa Jurusan yang masuk Top Dunia</strong></h1>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/melbourne/Management.jpg"/>
                                <h3>Hospitality Management</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/melbourne/cookery.jpg"/>
                                <h3>Cookery and Patisserie</h3>
                            </div>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/melbourne/Business.jpg"/>
                                <h3>Business Management & Accounting</h3>
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-lg-4'>
                                <img className='img-fluid mb-3' src="../../assets/img/jurusan/melbourne/Architect.jpg"/>
                                <h3>Architect, Building Design and Construction</h3>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );

    }
}

export default (CampusMelbourne);